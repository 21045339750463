<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <v-card>
          <v-toolbar>
            <v-toolbar-title>用户简历</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-btn @click="validateUser" :class="data.isvalid ? 'error' : 'success'">{{
              data.isvalid ? "取消认证" : "通过认证"
            }}</v-btn>
          </v-toolbar>
          <v-card-text class="grey--text text--darken-3">
            <v-container>
              <v-row>
                <v-col cols="12" md="3"></v-col>
                <v-col cols="12" md="6">
                  <img :src="data.face_photo" class="video" />
                </v-col>
                <v-col cols="12" md="3"></v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="1" style="text-align: right">
                  <span class="grey--text text--darken-1">姓名:</span>
                </v-col>
                <v-col cols="12" sm="2">
                  <span>{{ data.xingming }}</span>
                </v-col>
                <v-col cols="12" sm="1" style="text-align: right">
                  <span class="grey--text text--darken-1">性别:</span>
                </v-col>
                <v-col cols="12" sm="2">
                  <span>{{ data.xingbie }}</span>
                </v-col>
                <v-col cols="12" sm="1" style="text-align: right">
                  <span class="grey--text text--darken-1">身份证号:</span>
                </v-col>
                <v-col cols="12" sm="2">
                  <span>{{ data.sfz }}</span>
                </v-col>
                <v-col cols="12" sm="1" style="text-align: right">
                  <span class="grey--text text--darken-1">民族:</span>
                </v-col>
                <v-col cols="12" sm="2">
                  <span>{{ data.minzu }}</span>
                </v-col>
                <v-col cols="12" sm="1" style="text-align: right">
                  <span class="grey--text text--darken-1">政治面貌:</span>
                </v-col>
                <v-col cols="12" sm="2">
                  <span>{{ data.zhengzhimianmao }}</span>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="1" style="text-align: right">
                  <span class="grey--text text--darken-1">学历:</span>
                </v-col>
                <v-col cols="12" sm="2">
                  <span>{{ data.xueli }}</span>
                </v-col>
                <v-col cols="12" sm="1" style="text-align: right">
                  <span class="grey--text text--darken-1">毕业院校:</span>
                </v-col>
                <v-col cols="12" sm="2">
                  <span>{{ data.biyeyuanxiao }}</span>
                </v-col>
                <v-col cols="12" sm="1" style="text-align: right">
                  <span class="grey--text text--darken-1">专业:</span>
                </v-col>
                <v-col cols="12" sm="2">
                  <span>{{ data.zhuanye }}</span>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" sm="1" style="text-align: right">
                  <span class="grey--text text--darken-1">工作单位:</span>
                </v-col>
                <v-col cols="12" sm="2">
                  <span>{{ data.gongzuodanwei }}</span>
                </v-col>
                <v-col cols="12" sm="1" style="text-align: right">
                  <span class="grey--text text--darken-1">职务:</span>
                </v-col>
                <v-col cols="12" sm="2">
                  <span>{{ data.zhiwu }}</span>
                </v-col>
                <v-col cols="12" sm="1" style="text-align: right">
                  <span class="grey--text text--darken-1">职称:</span>
                </v-col>
                <v-col cols="12" sm="2">
                  <span>{{ data.zhicheng }}</span>
                </v-col>
                <v-col cols="12" sm="1" style="text-align: right">
                  <span class="grey--text text--darken-1">行业工作情况:</span>
                </v-col>
                <v-col cols="12" sm="2">
                  <span>{{ data.hygzqk }}</span>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" sm="1" style="text-align: right">
                  <span class="grey--text text--darken-1">电话:</span>
                </v-col>
                <v-col cols="12" sm="2">
                  <span>{{ data.dianhua }}</span>
                </v-col>
                <v-col cols="12" sm="1" style="text-align: right">
                  <span class="grey--text text--darken-1">电子邮件:</span>
                </v-col>
                <v-col cols="12" sm="2">
                  <span>{{ data.email }}</span>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="1" style="text-align: right">
                  <span class="grey--text text--darken-1">备注:</span>
                </v-col>
                <v-col cols="12" sm="11">
                  <span>{{ data.beizhu }}</span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-toolbar>
            <v-toolbar-title>培训经历</v-toolbar-title>
          </v-toolbar>
          <v-card-text class="grey--text text--darken-3">
            <v-data-table
              :headers="headers"
              :items="history.Payload"
              :server-items-length="history.Total"
              :loading="loading"
              hide-default-footer
              :fixed-header="true"
            >
              <template v-slot:item.progress="props">
                <v-progress-linear :value="props.item.progress" color="blue" height="20">
                  <template v-slot="{ value }">{{ value.toFixed(2) }}%</template>
                </v-progress-linear>
              </template>

              <template v-slot:no-data>尚未有人员参加培训</template>
            </v-data-table>
            <div class="text-xs-center pt-2">
              <v-pagination
                v-model="pagination.page"
                :length="pages"
                :total-visible="10"
              ></v-pagination>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card>
          <v-toolbar>
            <v-toolbar-title>受教育经历</v-toolbar-title>
          </v-toolbar>
          <v-card-text class="grey--text text--darken-3">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" width="150">从</th>
                    <th class="text-left" width="150">到</th>
                    <th class="text-left">教育机构</th>
                    <th class="text-left">证书</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in data.TrainingHistories" :key="item.id">
                    <td>{{ item.from | moment("LL") }}</td>
                    <td>{{ item.to | moment("LL") }}</td>
                    <td>{{ item.jigou }}</td>
                    <td>{{ item.zhengshu }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="6">
        <v-card>
          <v-toolbar>
            <v-toolbar-title class="grey--text text--darken-3">工作经历</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" width="150">从</th>
                    <th class="text-left" width="150">到</th>
                    <th class="text-left">工作单位</th>
                    <th class="text-left">职责</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in data.WorkHistories" :key="item.id">
                    <td>{{ item.from | moment("LL") }}</td>
                    <td>{{ item.to | moment("LL") }}</td>
                    <td>{{ item.gongzuodanwei }}</td>
                    <td>{{ item.zhize }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      loading: false,
      data: {},
      history: {},
      pagination: {
        page: 1,
        rowsPerPage: 10,
      },
      headers: [
        {
          text: "培训名称",
          value: "planname",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "开始时间",
          value: "from",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "结束时间",
          value: "to",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "学习进度",
          value: "progress",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "成绩",
          value: "totalscore",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
      ],
    };
  },
  mounted() {
    this.fetchUsers();
    this.fetchTrainingHistory();
  },
  watch: {
    id: {
      handler() {
        this.fetchUsers();
        this.fetchTrainingHistory();
      },
      deep: true,
    },
    pagination: {
      handler() {
        this.fetchTrainingHistory();
      },
      deep: true,
    },
  },
  computed: {
    pages() {
      if (this.history.Total) {
        return Math.ceil(this.history.Total / this.pagination.rowsPerPage);
      }
      return 0;
    },
  },
  methods: {
    fetchUsers() {
      this.loading = true;
      this.$hc
        .req()
        .get(
          `admin/members/profile/${this.id}?pagesize=${this.pagination.rowsPerPage}&pageindex=${this.pagination.page}`
        )
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.data = x.Data;
          },
          (x) => {}
        );
    },
    fetchTrainingHistory() {
      this.loading = true;
      this.$hc
        .req()
        .get(`admin/trainingmembers/traininghistory/${this.id}`)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.history = x.Data;
          },
          (x) => {}
        );
    },
    validateUser() {
      this.$hc
        .req()
        .put(`admin/members/validatemember/${this.id}`, {})
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "操作成功",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.fetchUsers();
            this.fetchTrainingHistory();
          },
          (x) => {}
        );
    },
  },
};
</script>
