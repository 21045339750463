<template>
  <v-card style="margin-top: 20px">
    <v-toolbar>
      <v-toolbar-title
        >第{{ quiz.ordindex }}题 {{ quiz.type }} 分值{{ quiz.point }}</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-switch
        v-model="quiz._show_options"
        inset
        label="显示选项"
        hide-details
      ></v-switch>
    </v-toolbar>
    <v-card-text>
      <v-container>
        <v-layout
          wrap
          v-if="quiz.type == '填空题' && quiz.user_answer && quiz.user_answer.length"
        >
          <v-flex xs12>
            <div v-html="quiz.title"></div>
          </v-flex>
          <template v-if="quiz._show_options">
            <v-flex xs4 v-for="(a, index) in quiz.user_answer" :key="index">
              <v-text-field :label="a.label" v-model="a.answer" disabled></v-text-field>
            </v-flex>
          </template>
        </v-layout>
        <v-layout wrap v-if="quiz.type == '单选题'">
          <v-flex xs12>
            <div v-html="quiz.title"></div>
          </v-flex>
          <v-flex xs12 v-if="quiz._show_options">
            <v-radio-group v-model="quiz.user_answer[0].answer">
              <v-radio
                v-for="(o, index) in quiz.options"
                :label="o.label"
                :key="index"
                :value="o.label"
                disabled
              >
                <template v-slot:label>
                  <span class="option-label"> {{ o.label }}</span>
                  <span class="option-text" v-html="o.title"></span>
                </template>
              </v-radio>
            </v-radio-group>
          </v-flex>
        </v-layout>
        <v-layout wrap v-if="quiz.type == '多选题'">
          <v-flex xs12>
            <div v-html="quiz.title"></div>
          </v-flex>
          <v-flex xs12 v-if="quiz._show_options">
            <v-checkbox
              v-for="(o, index) in quiz.options"
              disabled
              :label="o.label"
              :key="index"
              v-model="o.checked"
            >
              <template v-slot:label>
                <span class="option-label"> {{ o.label }}</span>
                <span class="option-text" v-html="o.title"></span>
              </template>
            </v-checkbox>
          </v-flex>
        </v-layout>
        <v-layout wrap v-if="quiz.type == '判断题'">
          <v-flex xs12>
            <div v-html="quiz.title"></div>
          </v-flex>
          <v-flex xs12 v-if="quiz._show_options">
            <v-radio-group v-model="quiz.user_answer[0].answer">
              <v-radio
                disabled
                v-for="(o, index) in quiz.options"
                :label="o.title"
                :key="index"
                :value="o.label"
              >
              </v-radio>
            </v-radio-group>
          </v-flex>
        </v-layout>
        <v-layout wrap v-if="quiz.type == '问答题'">
          <v-flex xs12>
            <div v-html="quiz.title"></div>
          </v-flex>
          <v-flex xs12 v-if="quiz._show_options">
            <v-textarea
              disabled
              label="回答"
              :rows="3"
              v-model="quiz.user_answer[0].answer"
              autocomplete="false"
            ></v-textarea>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
    <v-card-actions :class="quiz.verified ? 'green lighten-5' : 'deep-orange lighten-5'">
      <v-container>
        <v-row>
          <v-col cols="2" style="text-align: right">考生答案:</v-col>
          <v-col cols="10" style="text-align: left">{{ quiz.ua }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="2" style="text-align: right">正确答案:</v-col>
          <v-col cols="10" style="text-align: left">{{ quiz.ca }}</v-col>
        </v-row>
      </v-container>
    </v-card-actions>
    <v-toolbar :class="quiz.verified ? 'green lighten-5' : 'deep-orange lighten-5'">
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>

      <v-text-field
        label="得分"
        v-model="quiz.score"
        hide-details
        class="shrink mx-2"
      ></v-text-field>
      <v-switch
        v-model="quiz.verified"
        inset
        :label="quiz.verified ? '回答正确' : '回答错误'"
        @change="judgeAnswer(quiz)"
        hide-details
      ></v-switch>
    </v-toolbar>
  </v-card>
</template>

<script>
export default {
  name: "QualificationQuizVerify",
  props: ["quiz"],
  data() {
    return {};
  },
  methods: {
    judgeAnswer(quiz) {
      if (quiz.verified) {
        quiz.score = quiz.point;
      } else {
        quiz.score = 0;
      }
    },
  },
};
</script>
<style scoped>
.option-text {
  margin-left: 20px;
}
.option-text p {
  margin: 0px;
  padding: 0px !important;
}
</style>
