<template>
  <component :is="quizcomponent" :quizdata="quizdata"></component>
</template>
<script>
import QuizAnswerEditor from "./QuizAnswerEditor.vue";
import QuizInputEditor from "./QuizInputEditor.vue";
import QuizJudgementEditor from "./QuizJudgementEditor.vue";
import QuizMultipleEditor from "./QuizMultipleEditor.vue";
import QuizSingleEditor from "./QuizSingleEditor.vue";
export default {
  components: {
    QuizInputEditor,
    QuizAnswerEditor,
    QuizJudgementEditor,
    QuizSingleEditor,
    QuizMultipleEditor,
  },
  name: "QuizEditor",
  props: ["quizdata"],
  data() {
    return {
      type_components: {
        填空题: "QuizInputEditor",
        单选题: "QuizSingleEditor",
        多选题: "QuizMultipleEditor",
        判断题: "QuizJudgementEditor",
        问答题: "QuizAnswerEditor",
      },
    };
  },
  computed: {
    quizcomponent() {
      return this.type_components[this.quizdata.type];
    },
  },
  mounted() {},
  beforeDestroy() {},
  methods: {},
};
</script>
