<template>
  <div>
    <v-card>
      <v-card-title>修改个人信息</v-card-title>
      <v-tabs v-model="tab" background-color="basil" slider-color="grey" centered grow>
        <v-tab v-for="item in items" :key="item">{{ item }}</v-tab>
      </v-tabs>
      <v-card-text>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card flat style="margin: 25px">
              <v-card-text>
                <v-form lazy-validation v-model="profileFormValid" ref="profileForm">
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="3"></v-col>
                      <v-col cols="12" md="6">
                        <img
                          v-if="data.face_photo"
                          :src="data.face_photo"
                          class="video"
                        />
                      </v-col>
                      <v-col cols="12" md="3"> </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="3"></v-col>
                      <v-col cols="12" md="6">
                        <v-btn color="primary" @click="openCaptureDialog">拍照</v-btn>
                      </v-col>
                      <v-col cols="12" md="3"> </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="data.sfz"
                          :rules="data.id == 1 ? rules.sfzAdminRules : rules.sfzRules"
                          :counter="20"
                          maxlength="20"
                          label="账户(身份证号)"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="data.xingming"
                          :rules="rules.nameRules"
                          :counter="20"
                          maxlength="20"
                          label="姓名"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-select
                          :items="genderItems"
                          v-model="data.xingbie"
                          label="性别"
                          :rules="rules.xingbieRules"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="data.minzu"
                          :counter="20"
                          maxlength="20"
                          label="民族"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="data.zhengzhimianmao"
                          :counter="10"
                          maxlength="10"
                          label="政治面貌"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="data.xueli"
                          :counter="20"
                          maxlength="20"
                          label="学历"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="data.biyeyuanxiao"
                          :counter="50"
                          maxlength="50"
                          label="毕业院校"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="data.zhuanye"
                          :counter="50"
                          maxlength="50"
                          label="专业"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="data.gongzuodanwei"
                          :counter="50"
                          maxlength="50"
                          label="工作单位"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="data.zhiwu"
                          :counter="20"
                          maxlength="20"
                          label="职务"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="data.zhicheng"
                          :counter="50"
                          maxlength="50"
                          label="职称"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="data.hygzqk"
                          :counter="100"
                          maxlength="100"
                          label="行业工作情况"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="data.dianhua"
                          :counter="20"
                          maxlength="20"
                          label="电话"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="data.email"
                          :counter="255"
                          maxlength="255"
                          label="电子邮件"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="data.beizhu"
                          :counter="100"
                          maxlength="100"
                          label="备注"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  @click="saveUserInfo"
                  color="secondary"
                  :disabled="!profileFormValid"
                  >保存</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-title>
                <v-spacer></v-spacer>
                <v-btn color="secondary" @click="openCreateTraingDialog">添加</v-btn>
              </v-card-title>
              <v-card-text>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left" width="150">从</th>
                        <th class="text-left" width="150">到</th>
                        <th class="text-left">教育机构</th>
                        <th class="text-left">证书</th>
                        <th class="text-left" width="10"></th>
                        <th class="text-left" width="10"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in data.TrainingHistories" :key="item.id">
                        <td>{{ item.from | moment("LL") }}</td>
                        <td>{{ item.to | moment("LL") }}</td>
                        <td>{{ item.jigou }}</td>
                        <td>{{ item.zhengshu }}</td>
                        <td>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                color="success"
                                small
                                icon
                                v-on="on"
                                @click.native.stop="openEditTraingDialog(item)"
                              >
                                <v-icon small>edit</v-icon>
                              </v-btn>
                            </template>
                            <span>修改教育经历</span>
                          </v-tooltip>
                        </td>
                        <td>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                color="error"
                                small
                                icon
                                v-on="on"
                                @click.native.stop="removeTraining(item.id)"
                              >
                                <v-icon small>delete</v-icon>
                              </v-btn>
                            </template>
                            <span>删除教育经历</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card flat>
              <v-card-title>
                <v-spacer></v-spacer>
                <v-btn color="secondary" @click="openCreateWorkDialog">添加</v-btn>
              </v-card-title>
              <v-card-text>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left" width="150">从</th>
                        <th class="text-left" width="150">到</th>
                        <th class="text-left">工作单位</th>
                        <th class="text-left">职责</th>
                        <th class="text-left" width="10"></th>
                        <th class="text-left" width="10"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in data.WorkHistories" :key="item.id">
                        <td>{{ item.from | moment("LL") }}</td>
                        <td>{{ item.to | moment("LL") }}</td>
                        <td>{{ item.gongzuodanwei }}</td>
                        <td>{{ item.zhize }}</td>
                        <td>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                color="success"
                                small
                                icon
                                v-on="on"
                                @click.native.stop="openEditWorkDialog(item)"
                              >
                                <v-icon small>edit</v-icon>
                              </v-btn>
                            </template>
                            <span>修改工作经历</span>
                          </v-tooltip>
                        </td>
                        <td>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                color="error"
                                small
                                icon
                                v-on="on"
                                @click.native.stop="removeWork(item.id)"
                              >
                                <v-icon small>delete</v-icon>
                              </v-btn>
                            </template>
                            <span>删除工作经历</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
    <v-dialog v-model="addTrainingDialog" persistent max-width="600px">
      <v-form
        ref="addTrainingForm"
        v-model="addTrainingVM.valid"
        lazy-validation
        autocomplete="random-string"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ addTrianingDialogTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-menu
                    ref="trainingFromDialog"
                    v-model="trainingFromDialog"
                    persistent
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="addTrainingVM.d.from"
                        label="从"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="addTrainingVM.d.from"
                      no-title
                      @input="trainingFromDialog = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12>
                  <v-menu
                    ref="trainingToDialog"
                    v-model="trainingToDialog"
                    persistent
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="addTrainingVM.d.to"
                        label="到"
                        prepend-icon="event"
                        :rules="[confirmTo]"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="addTrainingVM.d.to"
                      no-title
                      @input="trainingToDialog = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    label="教育机构"
                    v-model="addTrainingVM.d.jigou"
                    :rules="rules.jigouRules"
                    prepend-icon="business"
                    autocomplete="false"
                    :counter="50"
                    maxlength="50"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    label="所获证书"
                    v-model="addTrainingVM.d.zhengshu"
                    prepend-icon="subtitles"
                    autocomplete="false"
                    :counter="50"
                    maxlength="50"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="
                addTrainingVM.d = { from: now, to: now };
                addTrainingDialog = false;
              "
              >取消</v-btn
            >
            <v-btn
              color="warning"
              :disabled="!addTrainingVM.valid"
              @click="createTraining"
              >保存</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="addWorkDialog" persistent max-width="600px">
      <v-form
        ref="addWorkForm"
        v-model="addWorkVM.valid"
        lazy-validation
        autocomplete="random-string"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ addWorkDialogTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-menu
                    ref="workFromDialog"
                    v-model="workFromDialog"
                    persistent
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="addWorkVM.d.from"
                        label="从"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="addWorkVM.d.from"
                      no-title
                      @input="workFromDialog = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12>
                  <v-menu
                    ref="workToDialog"
                    v-model="workToDialog"
                    persistent
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="addWorkVM.d.to"
                        label="到"
                        prepend-icon="event"
                        :rules="[confirmWorkTo]"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="addWorkVM.d.to"
                      no-title
                      @input="workToDialog = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    label="工作单位"
                    v-model="addWorkVM.d.gongzuodanwei"
                    :rules="rules.danweiRules"
                    prepend-icon="business"
                    autocomplete="false"
                    :counter="50"
                    maxlength="50"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    label="工作职责"
                    v-model="addWorkVM.d.zhize"
                    :rules="rules.zhizeRules"
                    prepend-icon="work"
                    autocomplete="false"
                    :counter="50"
                    maxlength="50"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="
                addWorkVM.d = { from: now, to: now };
                addWorkDialog = false;
              "
              >取消</v-btn
            >
            <v-btn color="warning" :disabled="!addWorkVM.valid" @click="createWork"
              >保存</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <camera-capture-dialog
      @onfinished="setphoto"
      ref="_captureDialog"
    ></camera-capture-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      now: this.$moment().format("YYYY-MM-DD"),
      addTrainingDialog: false,
      addTrianingDialogTitle: "",
      addWorkDialog: false,
      addWorkDialogTitle: "",
      loading: false,
      profileFormValid: false,
      trainingFromDialog: false,
      trainingToDialog: false,
      workFromDialog: false,
      workToDialog: false,
      addTrainingVM: {
        d: {},
      },
      addWorkVM: {
        d: {},
      },
      data: {},
      tab: 0,
      items: ["个人信息", "教育经历", "工作经历"],
      genderItems: ["男", "女"],
      rules: {
        sfzRules: [
          (v) => !!v || "请填写账户(身份证号)",
          (v) =>
            (v && v.length <= 20 && v.length >= 15) ||
            "账户(身份证号)长度必须在15到20个字符之间",
          (v) => /^[0-9xX]*$/.test(v) || "账户(身份证号)不正确",
        ],
        xingbieRules: [(v) => !!v || "请选择性别"],
        sfzAdminRules: [(v) => !!v || "请填写账户(身份证号)"],
        nameRules: [(v) => !!v || "请填写姓名"],
        jigouRules: [(v) => !!v || "请填写教育机构"],
        danweiRules: [(v) => !!v || "请填写工作单位"],
        zhizeRules: [(v) => !!v || "请填写工作职责"],
      },
    };
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    confirmTo(value) {
      var from = this.$moment(this.addTrainingVM.d.from);
      var to = this.$moment(value);
      var d = from.diff(to, "days");
      if (d >= 0) return "结束时间必须大于起始时间";
      return true;
    },
    confirmWorkTo(value) {
      var from = this.$moment(this.addWorkVM.d.from);
      var to = this.$moment(value);
      var d = from.diff(to, "days");
      if (d >= 0) return "结束时间必须大于起始时间";
      return true;
    },
    getUserInfo() {
      this.loading = true;
      this.$hc
        .req()
        .get(`members/profile`)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.data = x.Data;
          },
          (x) => {}
        );
    },
    saveUserInfo() {
      if (!this.$refs.profileForm.validate()) return;
      this.$hc
        .req()
        .put(`members/profile`, this.data)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "保存成功",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            //this.getUserInfo();
            this.$router.go(0);
          },
          (x) => {}
        );
    },
    removeTraining(id) {
      this.$confirm("确定要删除吗? 该操作无法恢复").then((x) => {
        if (x) {
          this.$hc
            .req()
            .delete(`members/traininghistory/${id}`)
            .go({
              lock: true,
              toastError: true,
              toastSuccess: "删除成功",
            })
            .subscribe(
              (x) => {
                this.getUserInfo();
              },
              (x) => {}
            );
        }
      });
    },
    removeWork(id) {
      this.$confirm("确定要删除吗? 该操作无法恢复").then((x) => {
        if (x) {
          this.$hc
            .req()
            .delete(`members/workhistory/${id}`)
            .go({
              lock: true,
              toastError: true,
              toastSuccess: "删除成功",
            })
            .subscribe(
              (x) => {
                this.getUserInfo();
              },
              (x) => {}
            );
        }
      });
    },
    openCreateTraingDialog() {
      this.addTrianingDialogTitle = "添加教育经历";
      if (this.$refs.addTrainingForm) {
        this.$refs.addTrainingForm.resetValidation();
      }
      this.addTrainingVM.d = { from: this.now, to: this.now };
      this.addTrainingDialog = true;
    },
    openEditTraingDialog(item) {
      if (this.$refs.addTrainingForm) {
        this.$refs.addTrainingForm.resetValidation();
      }
      this.addTrianingDialogTitle = "修改教育经历";
      Object.assign(this.addTrainingVM.d, item);
      this.addTrainingDialog = true;
    },
    createTraining() {
      if (!this.$refs.addTrainingForm.validate()) return;
      this.$hc
        .req()
        .post(`members/traininghistory`, this.addTrainingVM.d)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "操作完成",
        })
        .subscribe(
          (x) => {
            this.addTrainingDialog = false;
            this.getUserInfo();
          },
          (x) => {}
        );
    },
    openCreateWorkDialog() {
      this.addWorkDialogTitle = "添加工作经历";
      if (this.$refs.addWorkForm) {
        this.$refs.addWorkForm.resetValidation();
      }
      this.addWorkVM.d = { from: this.now, to: this.now };
      this.addWorkDialog = true;
    },
    openEditWorkDialog(item) {
      if (this.$refs.addWorkForm) {
        this.$refs.addWorkForm.resetValidation();
      }
      this.addWorkDialogTitle = "修改工作经历";
      Object.assign(this.addWorkVM.d, item);
      this.addWorkDialog = true;
    },
    createWork() {
      if (!this.$refs.addWorkForm.validate()) return;
      this.$hc
        .req()
        .post(`members/workhistory`, this.addWorkVM.d)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "操作完成",
        })
        .subscribe(
          (x) => {
            this.addWorkDialog = false;
            this.getUserInfo();
          },
          (x) => {}
        );
    },
    openCaptureDialog() {
      this.$refs._captureDialog.listDevices();
    },
    setphoto(data) {
      this.data.face_feature = data.face_feature;
      this.data.face_photo = data.face_photo;
    },
  },
};
</script>
