<template>
  <div>
    <v-card>
      <v-card-title>
        <small>{{ title }}</small>
        <v-spacer></v-spacer>
        <v-btn color="secondary" @click="openAddDialog">添加</v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="data.Payload"
          :loading="loading"
          hide-default-footer
          :server-items-length="data.Total"
          item-key="id"
          :fixed-header="true"
        >
          <template v-slot:item.created="props">{{
            props.item.created | moment("YYYY-MM-DD HH:mm")
          }}</template>
          <template v-slot:item.op="props">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="secondary"
                  small
                  icon
                  v-on="on"
                  :href="articleurl(props.item.id)"
                  target="_blank"
                >
                  <v-icon small>remove_red_eye</v-icon>
                </v-btn>
              </template>
              <span>预览文章</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  small
                  icon
                  v-on="on"
                  @click.native.stop="openEditDialog(props.item)"
                >
                  <v-icon small>edit</v-icon>
                </v-btn>
              </template>
              <span>编辑</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="error"
                  small
                  icon
                  v-on="on"
                  @click.native.stop="deleteCourseware(props.item.id)"
                >
                  <v-icon small>delete</v-icon>
                </v-btn>
              </template>
              <span>删除</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data></template>
        </v-data-table>
      </v-card-text>
      <div class="text-xs-center pt-2">
        <v-pagination
          v-model="pagination.page"
          :length="pages"
          :total-visible="10"
        ></v-pagination>
      </div>
    </v-card>

    <v-dialog v-model="dialogSet.open" persistent max-width="1200px">
      <v-form
        ref="addForm"
        v-model="dialogSet.vm.valid"
        lazy-validation
        autocomplete="random-string"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ dialogSet.title }}</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    name="hack-chrome"
                    label="标题"
                    v-model="dialogSet.vm.data.title"
                    :rules="rules.mingchengRules"
                    prepend-icon="payment"
                    autocomplete="false"
                    :counter="50"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 style="min-height: 300px">
                  <vue-editor
                    v-model="dialogSet.vm.data.content"
                    :editor-toolbar="toolbar"
                  ></vue-editor>
                </v-flex>

                <v-flex xs12>
                  <v-file-input
                    label="附件上传"
                    v-model="dialogSet.vm.data.file"
                    accept=".zip"
                  ></v-file-input>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="
                dialogSet.vm.data = {};
                dialogSet.open = false;
              "
              >取消</v-btn
            >
            <v-btn
              color="warning"
              :disabled="!dialogSet.vm.valid"
              @click="createCourseware"
              >保存</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["id"],

  data() {
    return {
      toolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["clean"], // remove formatting button
      ],
      q: "",
      loading: true,
      dialogSet: {
        detailsOpen: false,
        details: {},
        open: false,
        title: "",
        isedit: false,
        vm: {
          valid: false,
          data: {
            content: "",
          },
        },
      },
      pagination: {
        page: 1,
        rowsPerPage: 15,
      },
      data: {},
      coursewares: {},
      rules: {
        mingchengRules: [(v) => !!v || "请填写标题"],
        descriptionRules: [(v) => !!v || "请填写内容"],
        videoRules: [(v) => !!v || "请选择附件"],
      },
      headers: [
        {
          text: "ID",
          value: "id",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },

        {
          text: "标题",
          value: "title",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "创建时间",
          value: "created",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "操作",
          value: "op",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
      ],
    };
  },
  watch: {
    id: {
      handler() {
        this.fetchCoursewares();
      },
      deep: true,
    },
    pagination: {
      handler() {
        this.fetchCoursewares();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchCoursewares();
  },

  computed: {
    title() {
      if (this.id == 0) {
        return "政策法规";
      } else if (this.id == 1) {
        return "环保标准";
      } else if (this.id == 2) {
        return "环境质量报告书";
      }
      return "";
    },
    pages() {
      if (this.data.Total) {
        return Math.ceil(this.data.Total / this.pagination.rowsPerPage);
      }
      return 0;
    },
  },
  methods: {
    confirmTo() {},
    confirmEnd() {},
    search() {
      this.fetchCoursewares();
    },
    articleurl(id) {
      return this.$router.resolve({ name: "Article", params: { id: id } }).href;
    },
    fetchCoursewares() {
      this.loading = true;
      this.$hc
        .req()
        .get(
          `admin/topics?type=${this.id}&pagesize=${this.pagination.rowsPerPage}&pageindex=${this.pagination.page}`
        )
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.data = x.Data;
          },
          (x) => {}
        );
    },

    openEditDialog(vm) {
      this.dialogSet.vm.data.file = null;
      this.dialogSet.title = "编辑";
      this.dialogSet.isedit = true;
      this.rules.videoRules = [];
      if (this.$refs.addForm) {
        this.$refs.addForm.resetValidation();
      }
      Object.assign(this.dialogSet.vm.data, vm);
      this.dialogSet.open = true;
    },
    openAddDialog() {
      this.dialogSet.vm.data.file = null;
      this.dialogSet.title = "添加";
      this.dialogSet.showVideo = false;
      this.dialogSet.isedit = false;
      //this.rules.videoRules = [(v) => !!v || "请填选择上传课件压缩包"];
      this.dialogSet.vm.data = {};

      this.dialogSet.open = true;
      if (this.$refs.addForm) {
        this.$refs.addForm.resetValidation();
      }
    },
    createCourseware() {
      if (!this.$refs.addForm.validate()) return;
      if (this.dialogSet.vm.data.id) {
        var fe = new FormData();
        fe.append("id", this.dialogSet.vm.data.id);
        fe.append("title", this.dialogSet.vm.data.title);
        fe.append("type", this.id);
        fe.append("content", this.dialogSet.vm.data.content);
        if (this.dialogSet.vm.data.file) {
          fe.append("file", this.dialogSet.vm.data.file);
        }
        this.$hc
          .req()
          .put(`admin/topics`, fe)
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功",
          })
          .subscribe(
            (x) => {
              this.fetchCoursewares();
              this.dialogSet.open = false;
            },
            (x) => {}
          );
      } else {
        var fd = new FormData();

        fd.append("title", this.dialogSet.vm.data.title);
        fd.append("type", this.id);
        fd.append("content", this.dialogSet.vm.data.content);
        if (this.dialogSet.vm.data.file) {
          fd.append("file", this.dialogSet.vm.data.file);
        }
        this.$hc
          .req()
          .post(`admin/topics`, fd)
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功",
          })
          .subscribe(
            (x) => {
              this.fetchCoursewares();
              this.dialogSet.open = false;
            },
            (x) => {}
          );
      }
    },
    deleteCourseware(id) {
      this.$confirm("确定要删除吗? 该操作无法恢复").then((x) => {
        if (x) {
          this.$hc
            .req()
            .delete(`admin/topics/${id}`)
            .go({
              lock: true,
              toastError: true,
              toastSuccess: "操作成功",
            })
            .subscribe(
              (x) => {
                this.fetchCoursewares();
              },
              (x) => {}
            );
        }
      });
    },
  },
};
</script>
