<template>
  <div>
    <v-navigation-drawer v-model="drawer" clipped app permanent>
      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-item @click.stop to="/activelectures" dense v-if="isUserValid">
          <v-list-item-icon>
            <v-icon>dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>我的课程</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click.stop to="/dashboard" v-if="isUserValid">
          <v-list-item-icon>
            <v-icon>alarm</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>即将开始</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group
          no-action
          prepend-icon="supervised_user_circle"
          v-if="isUserValid && trainingTypes && trainingTypes.length > 0"
        >
          <template v-slot:activator>
            <v-list-item-title>我的培训</v-list-item-title>
          </template>

          <v-list-item
            @click.stop
            :to="{ name: 'mytraining', params: { type: c } }"
            v-for="c in trainingTypes"
            :key="c"
          >
            <v-list-item-content>
              <v-list-item-title>{{ c }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item @click.stop to="/coursewares" v-if="isUserValid">
          <v-list-item-icon>
            <v-icon>cloud_download</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>课件下载</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click.stop to="/examinations" v-if="isUserValid">
          <v-list-item-icon>
            <v-icon>content_paste</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>考试练习</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click.stop to="/qualifications" v-if="isUserValid">
          <v-list-item-icon>
            <v-icon>content_paste</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>在线考试</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group no-action prepend-icon="art_track" v-if="isUserValid">
          <template v-slot:activator>
            <v-list-item-title>专题文章</v-list-item-title>
          </template>

          <v-list-item @click.stop to="/topics/0">
            <v-list-item-content>
              <v-list-item-title>政策法规</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click.stop to="/topics/1">
            <v-list-item-content>
              <v-list-item-title>环保标准</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click.stop to="/topics/2">
            <v-list-item-content>
              <v-list-item-title>环境报告</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item @click.stop to="/topiclectures" v-if="isUserValid">
          <v-list-item-icon>
            <v-icon>school</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>专题课程</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click.stop="openFeedbackDialog()" v-if="isUserValid">
          <v-list-item-action>
            <v-icon>feedback</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>意见反馈</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click.stop to="/experts" v-if="isUserValid">
          <v-list-item-action>
            <v-icon>badge</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>专家库</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click.stop to="/userprofile">
          <v-list-item-action>
            <v-icon>person</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>个人信息</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click.stop to="/changepassword">
          <v-list-item-action>
            <v-icon>security</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>修改密码</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <template v-if="isUserAdmin">
          <v-subheader class="mt-3 grey--text text--darken-1">系统管理</v-subheader>
          <v-list-item @click.stop to="/admin/trainingplans">
            <v-list-item-action>
              <v-icon>timelapse</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>培训计划</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click.stop to="/admin/coursewares">
            <v-list-item-action>
              <v-icon>cloud_upload</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>课件管理</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click.stop to="/admin/examinations">
            <v-list-item-action>
              <v-icon>content_paste</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>考试管理</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group no-action prepend-icon="content_paste">
            <template v-slot:activator>
              <v-list-item-title>在线考试</v-list-item-title>
            </template>
            <v-list-item @click.stop to="/admin/qualifications">
              <v-list-item-content>
                <v-list-item-title>考试管理</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click.stop to="/admin/qualificationquizbase">
              <v-list-item-content>
                <v-list-item-title>考试题库</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-group no-action prepend-icon="art_track">
            <template v-slot:activator>
              <v-list-item-title>专题文章</v-list-item-title>
            </template>

            <v-list-item @click.stop to="/admin/topics/0">
              <v-list-item-content>
                <v-list-item-title>政策法规</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click.stop to="/admin/topics/1">
              <v-list-item-content>
                <v-list-item-title>环保标准</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click.stop to="/admin/topics/2">
              <v-list-item-content>
                <v-list-item-title>环境报告</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item @click.stop to="/admin/topiclectures">
            <v-list-item-icon>
              <v-icon>school</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>专题课程</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click.stop to="/admin/feedbacks">
            <v-list-item-action>
              <v-icon>cached</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>培训反馈</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click.stop to="/admin/opinions">
            <v-list-item-action>
              <v-icon>feedback</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>意见反馈</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click.stop to="/admin/systemmessage">
            <v-list-item-action>
              <v-icon>message</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>系统公告</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/admin/userlist">
            <v-list-item-action>
              <v-icon>supervisor_account</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>用户管理</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click.stop to="/admin/managers">
            <v-list-item-action>
              <v-icon>how_to_reg</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>管理员设置</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view></router-view>
    </v-main>

    <v-dialog v-model="dialogSet.feedbackOpen" persistent max-width="600px">
      <v-form
        ref="addForm"
        v-model="dialogSet.feedback.valid"
        lazy-validation
        autocomplete="random-string"
      >
        <v-card>
          <v-card-title>
            <span class="headline">意见反馈</span>
          </v-card-title>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="dialogSet.feedback.data.yijian"
                  :rules="rules.yijianRules"
                  :counter="200"
                  maxlength="200"
                  label="意见"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="dialogSet.feedbackOpen = false">取消</v-btn>
            <v-btn
              color="warning"
              :disabled="!dialogSet.feedback.valid"
              @click="addFeedback(dialogSet.feedback.data)"
              >保存</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { useUserStore } from "@/stores/userStore.js";
export default {
  name: "App",
  data: () => ({
    loading: false,
    dialogSet: {
      detailsOpen: false,
      feedbackOpen: false,
      training: {},
      feedback: {
        valid: false,
        data: {},
      },
    },
    rules: {
      yijianRules: [(v) => !!v || "请填写意见"],
    },
    dark: false,
    user: null,
    drawer: true,
    trainingTypes: ["测试", "test"],
  }),
  props: {
    source: String,
  },
  computed: {
    ...mapState(useUserStore, ["profile", "isUserValid", "isUserAdmin"]),
  },
  mounted() {
    this.fetchPlanTypes();
  },
  methods: {
    fetchPlanTypes() {
      this.loading = true;
      this.$hc
        .req()
        .get(`trainingplans/types/list`)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.trainingTypes = x.Data;
          },
          (x) => {}
        );
    },
    openFeedbackDialog() {
      this.dialogSet.feedback.data.yijian = "";
      this.dialogSet.feedbackOpen = true;
    },
    addFeedback(vm) {
      if (!this.$refs.addForm.validate()) return;
      this.$hc
        .req()
        .post(`opinions`, vm)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "操作成功",
        })
        .subscribe(
          (x) => {
            this.dialogSet.feedbackOpen = false;
          },
          (x) => {}
        );
    },
  },
};
</script>
