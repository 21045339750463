<template>
  <v-card style="margin-top: 20px">
    <v-toolbar class="blue-grey lighten-5">
      <v-toolbar-title
        >第{{ quiz.ordindex }}题 {{ quiz.type }} 分值{{ quiz.point }}</v-toolbar-title
      >
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>
      <v-container>
        <v-layout
          wrap
          v-if="quiz.type == '填空题' && quiz.user_answer && quiz.user_answer.length"
        >
          <v-flex xs12>
            <div v-html="quiz.title"></div>
          </v-flex>
          <v-flex xs4 v-for="(a, index) in quiz.user_answer" :key="index">
            <v-text-field :label="a.label" v-model="a.answer"></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout wrap v-if="quiz.type == '单选题'">
          <v-flex xs12>
            <div v-html="quiz.title"></div>
          </v-flex>
          <v-flex xs12>
            <v-radio-group v-model="quiz.user_answer[0].answer">
              <v-radio
                v-for="(o, index) in quiz.options"
                :label="o.label"
                :key="index"
                :value="o.label"
              >
                <template v-slot:label>
                  <span class="option-label"> {{ o.label }}</span>
                  <span class="option-text" v-html="o.title"></span>
                </template>
              </v-radio>
            </v-radio-group>
          </v-flex>
        </v-layout>
        <v-layout wrap v-if="quiz.type == '多选题'">
          <v-flex xs12>
            <div v-html="quiz.title"></div>
          </v-flex>
          <v-flex xs12>
            <v-checkbox
              v-for="(o, index) in quiz.options"
              :label="o.label"
              :key="index"
              v-model="o.checked"
            >
              <template v-slot:label>
                <span class="option-label"> {{ o.label }}</span>
                <span class="option-text" v-html="o.title"></span>
              </template>
            </v-checkbox>
          </v-flex>
        </v-layout>
        <v-layout wrap v-if="quiz.type == '判断题'">
          <v-flex xs12>
            <div v-html="quiz.title"></div>
          </v-flex>
          <v-flex xs12>
            <v-radio-group v-model="quiz.user_answer[0].answer">
              <v-radio
                v-for="(o, index) in quiz.options"
                :label="o.title"
                :key="index"
                :value="o.label"
              >
              </v-radio>
            </v-radio-group>
          </v-flex>
        </v-layout>
        <v-layout wrap v-if="quiz.type == '问答题'">
          <v-flex xs12>
            <div v-html="quiz.title"></div>
          </v-flex>
          <v-flex xs12>
            <v-textarea
              label="回答"
              :rows="3"
              v-model="quiz.user_answer[0].answer"
              autocomplete="false"
            ></v-textarea>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "QualificationQuiz",
  props: ["quiz"],
  data() {
    return {};
  },
};
</script>
<style scoped>
.option-text {
  margin-left: 20px;
}
.option-text p {
  margin: 0px;
  padding: 0px !important;
}
</style>
