<template>
  <v-dialog v-model="open" persistent max-width="850px">
    <v-form ref="form" v-model="valid" lazy-validation autocomplete="random-string">
      <v-card>
        <v-card-title>
          <span class="headline">{{ title }}</span>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="vm.title"
            :rules="rules.title"
            autocomplete="false"
            label="大题名称"
            type="text"
          ></v-text-field>
          <vue-editor
            v-model="vm.description"
            :editor-toolbar="toolbar"
            placeholder="描述"
          ></vue-editor>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="$emit('update:open', false)">取消</v-btn>
          <v-btn color="success" :disabled="!valid" @click="createGroup">保存</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: "QuizGroupEditDialog",
  props: ["open", "qualificationid"],
  data() {
    return {
      vm: {
        id: 0,
        title: "",
        description: "",
      },
      valid: false,
      toolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["clean"], // remove formatting button
        ["image"],
      ],
      rules: {
        title: [(v) => !!v || "请填大题名称"],
      },
    };
  },
  computed: {
    title() {
      if (this.vm.id) return "修改大题";
      return "创建大题";
    },
  },

  methods: {
    loadData(d) {
      if (d) {
        this.vm.id = d.id;
        this.vm.title = d.title;
        this.vm.description = d.description;
      } else {
        this.vm.id = 0;
        this.vm.title = "";
        this.vm.description = "";
      }
      this.$forceUpdate();
    },
    createGroup() {
      if (!this.$refs.form.validate()) return;
      this.vm.qualificationid = this.qualificationid;
      if (this.vm.id) {
        this.$hc
          .req()
          .put(`admin/qualification/groups`, this.vm)
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功",
          })
          .subscribe(
            (x) => {
              this.vm = {};
              this.$emit("success");
              this.$emit("update:open", false);
            },
            (x) => {}
          );
      } else {
        this.$hc
          .req()
          .post(`admin/qualification/groups`, this.vm)
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功",
          })
          .subscribe(
            (x) => {
              this.vm = {};
              this.$emit("success");
              this.$emit("update:open", false);
            },
            (x) => {}
          );
      }
    },
  },
};
</script>
