<template>
  <v-dialog v-model="open" persistent max-width="850px">
    <v-form ref="form" v-model="valid" lazy-validation autocomplete="random-string">
      <v-card>
        <v-card-title>
          <span class="headline">拍照</span>
        </v-card-title>
        <v-card-subtitle class="pb-0">
          请连接好摄像头, 选择你的摄像头。确保您的面部正对摄像头, 然后点击[拍照]。<br />
          右侧会出现拍摄的照片, 系统正确识别出人脸后, [确定]按钮将变为可用状态,
          如按钮扔不可用请调整您的面部姿态重新点击[拍照]直至[确定]按钮可用,
          拍出满意照片后点击[确定按钮]完成拍照
        </v-card-subtitle>
        <v-card-text>
          <v-row align="center">
            <v-col cols="6">
              <v-select
                :items="devices"
                v-model="deviceId"
                label="选择摄像头"
                item-text="name"
                item-value="id"
                @change="startVideo"
              ></v-select>
            </v-col>
            <v-col cols="6"
              ><v-btn @click="capture" :disabled="(deviceId || '') == ''"
                >拍照</v-btn
              ></v-col
            >
          </v-row>

          <v-row align="center">
            <v-col cols="6">
              <video ref="_video" @playing="onVideoPlay" class="video" autoplay></video>
            </v-col>
            <v-col cols="6">
              <canvas ref="_capture" class="video"></canvas>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="cancelDialog">取消</v-btn>
          <v-btn color="warning" :disabled="!canSave" @click="finish">确定</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: "CameraCaptureDialog",
  props: ["data"],
  data() {
    return {
      open: false,
      valid: false,
      canSave: false,
      face_feature: "",
      face_photo: "",
      deviceId: "",
      devices: [],
    };
  },
  beforeDestroy() {
    this.closeCamera();
  },
  mounted() {},
  methods: {
    reset() {
      this.face_feature = "";
      this.face_photo = "";
      this.devices = [];
      this.deviceId = "";
      this.canSave = false;
      if (this.$refs && this.$refs._capture) {
        this.$refs._capture.width = 0;
        this.$refs._capture.height = 0;
      }
    },
    onVideoPlay() {
      this.$refs._capture.width = this.$refs._video.clientWidth;
      this.$refs._capture.height = this.$refs._video.clientHeight;
    },
    capture() {
      this.$ns.cast("lock", true);
      setTimeout(() => {
        var fapi = this.$face;
        this.$refs._capture
          .getContext("2d")
          .drawImage(
            this.$refs._video,
            0,
            0,
            this.$refs._video.clientWidth,
            this.$refs._video.clientHeight
          );

        this.$face._api
          .detectSingleFace(this.$refs._capture)
          .withFaceLandmarks()
          .withFaceDescriptor()
          .then((x) => {
            if (x) {
              this.face_feature = fapi.saveFaceFeature(x.descriptor);
              this.face_photo = this.$refs._capture.toDataURL("image/jpeg");
              this.canSave = true;
            } else {
              this.$ns.cast("snack", {
                text: "未能识别出人脸, 请调整面部姿态并重试",
                color: "warning",
              });
            }
            this.$ns.cast("lock", false);
          });
      }, 500);
    },
    listDevices() {
      this.open = true;
      this.reset();
      var self = this;
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: false })
        .then((x) => {
          window.__stream = x;
          navigator.mediaDevices.enumerateDevices().then((devices) => {
            for (var i = 0; i < devices.length; i++) {
              var d = devices[i];
              if (d.deviceId && d.kind == "videoinput") {
                self.devices.push({
                  name: d.label,
                  id: d.deviceId,
                });
              }
            }
            const tracks = x.getTracks();
            tracks.forEach((x) => x.stop());
            window.__stream = null;
          });
        })
        .catch((x) => {});
    },
    cancelDialog() {
      this.closeCamera();
      this.reset();
      this.open = false;
    },
    closeCamera() {
      if (window.__stream) {
        window.__stream.getTracks().forEach((track) => {
          track.stop();
        });
        window.__stream = null;
      }
    },
    startVideo() {
      this.closeCamera();
      var self = this;
      navigator.mediaDevices
        .getUserMedia({
          video: {
            deviceId: { exact: self.deviceId },
          },
          audio: false,
        })
        .then((x) => {
          window.__stream = x;
          self.$refs._video.srcObject = x;
        })
        .catch((error) => {});
    },
    finish() {
      this.closeCamera();
      this.open = false;
      this.$emit("onfinished", {
        face_feature: this.face_feature,
        face_photo: this.face_photo,
      });
    },
  },
};
</script>
