<template>
  <v-dialog v-model="open" persistent max-width="75%">
    <v-card>
      <v-card-title>
        <small>从题库选取题目(已选择 {{ selectedCount }} 个)</small>
        <v-spacer></v-spacer>
        <v-text-field
          label="题目"
          v-model="q.title"
          dense
          hide-details=""
          autocomplete="false"
          class="ma-2"
          style="max-width: 150px"
        ></v-text-field>
        <v-text-field
          label="类别"
          v-model="q.category"
          dense
          hide-details=""
          class="ma-2"
          autocomplete="false"
          style="max-width: 150px"
        ></v-text-field>
        <v-select
          :items="quizType"
          v-model="q.type"
          label="题型"
          item-text="label"
          item-value="value"
          dense
          style="max-width: 100px"
          hide-details=""
        ></v-select>
        <v-select
          :items="diffcultyType"
          v-model="q.diffculty"
          label="难度"
          item-text="label"
          item-value="value"
          dense
          style="max-width: 100px"
          hide-details=""
        ></v-select>
        <v-btn icon color="blue" @click="fetchData">
          <v-icon>search</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="data.Payload"
          :loading="loading"
          hide-default-footer
          disable-pagination
          item-key="id"
        >
          <template v-slot:item.op="props">
            <v-checkbox
              style="padding: 0px; margin: 0px"
              hide-details
              v-model="selectedQuizs[props.item.id]"
            ></v-checkbox>
          </template>
          <template v-slot:no-data></template>
        </v-data-table>
      </v-card-text>
      <div class="text-xs-center pt-2">
        <v-pagination
          v-model="pagination.page"
          :length="pages"
          :total-visible="10"
        ></v-pagination>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="open = false">取消</v-btn>
        <v-btn color="success" @click="addToGroup">添加选中题目</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PickQuizFromBaseDialog",
  props: ["qualificationid"],
  data() {
    return {
      loading: true,
      open: false,
      data: {},
      groupid: 0,
      quizDialog: false,
      selectedQuizs: {},
      q: {
        title: "",
        category: "",
        type: "",
        diffculty: "",
      },
      pagination: {
        page: 1,
        rowsPerPage: 10,
      },
      quizType: [
        { label: "不限", value: "" },
        { label: "单选题", value: "单选题" },
        { label: "多选题", value: "多选题" },
        { label: "填空题", value: "填空题" },
        { label: "判断题", value: "判断题" },
        { label: "问答题", value: "问答题" },
        { label: "单选题", value: "单选题" },
      ],
      diffcultyType: [
        { label: "不限", value: "" },
        { label: "简单", value: "简单" },
        { label: "中等", value: "中等" },
        { label: "困难", value: "困难" },
        { label: "极难", value: "极难" },
      ],
      headers: [
        {
          text: "ID",
          value: "id",
          align: "center",
          sortable: false,
          class: "data-table-header",
          width: "100px",
        },
        {
          text: "题目",
          value: "title2",
          align: "center",
          sortable: false,
          class: "data-table-header text-hide-overflow-dot",
        },
        {
          text: "类型",
          value: "type",
          align: "center",
          sortable: false,
          class: "data-table-header",
          width: "100px",
        },
        {
          text: "类别",
          value: "category",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "难度",
          value: "diffculty",
          align: "center",
          sortable: false,
          class: "data-table-header",
          width: "100px",
        },
        {
          text: "分值",
          value: "point",
          align: "center",
          sortable: false,
          class: "data-table-header",
          width: "100px",
        },
        {
          text: "选择",
          value: "op",
          align: "center",
          sortable: false,
          class: "data-table-header",
          width: "10px",
        },
      ],
    };
  },
  watch: {
    id: {
      handler() {
        if (this.open) {
          this.fetchData();
        }
      },
      deep: true,
    },
    pagination: {
      handler() {
        if (this.open) {
          this.fetchData();
        }
      },
      deep: true,
    },
  },
  computed: {
    pages() {
      if (this.data && this.data.Total) {
        return Math.ceil(this.data.Total / this.pagination.rowsPerPage);
      }
      return 0;
    },
    selectedCount() {
      if (open) {
        var ps = Object.getOwnPropertyNames(this.selectedQuizs).filter(
          (x) => !isNaN(x * 1) && this.selectedQuizs[x]
        );
        return ps.length;
      }
      return "0";
    },
  },
  methods: {
    openDialog(groupid) {
      this.groupid = groupid;
      this.q = {
        title: "",
        category: "",
        type: "",
        diffculty: "",
      };
      this.pagination = {
        page: 1,
        rowsPerPage: 10,
      };
      this.open = true;
      this.selectedQuizs = {};
      this.fetchData();
    },
    search() {
      this.fetchData();
    },
    fetchData() {
      this.loading = true;
      this.$hc
        .req()
        .get(
          `admin/qualificationquizbase?pagesize=${
            this.pagination.rowsPerPage
          }&pageindex=${this.pagination.page}&title=${this.q.title || ""}&category=${
            this.q.category || ""
          }&type=${this.q.type || ""}&diffculty=${this.q.diffculty || ""}`
        )
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.data = x.Data;
          },
          (x) => {}
        );
    },
    addToGroup() {
      this.loading = true;
      var ps = Object.getOwnPropertyNames(this.selectedQuizs).filter(
        (x) => !isNaN(x * 1) && this.selectedQuizs[x]
      );
      if (!ps.length) {
        return;
      }
      this.$hc
        .req()
        .post(`admin/qualification/groups/addquizs`, {
          groupid: this.groupid,
          quiz_base_id: ps,
        })
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.$emit("success");
            this.open = false;
          },
          (x) => {}
        );
    },
  },
};
</script>
