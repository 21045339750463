import { defineStore } from "pinia";
import Vue from "vue";

export const useUserStore = defineStore("user", {
  state: () => ({
    token: null,
    profile: null,
    header_tab: -1,
  }),
  getters: {
    userToken: (state) => {
      try {
        if (state.token) return state.token;
        else {
          const tk = Vue.ls.get("token", null, String);
          if (tk) {
            let u = JSON.parse(tk);
            return u;
          }
        }
      } catch (err) {}
      return null;
    },
    isUserValid: (state) => {
      if (!state.userToken) return false;
      if (!state.profile) return false;
      if (state.profile.isadmin) return true;
      return state.profile.isvalid;
    },
    isUserAdmin: (state) => {
      if (!state.userToken) return false;
      if (!state.profile) return false;
      return state.profile.isadmin;
    },
  },
  actions: {
    login(app, vm) {
      Vue.hc
        .req()
        .post("members/login", vm, (x) => {
          Vue.ls.set("token", JSON.stringify(x.Data));
          this.$patch({
            token: x.Data,
          });
        })
        .get("members/profile", (x) => {
          this.$patch({ profile: x.Data });
        })
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "登陆成功",
        })
        .subscribe(
          (x) => {
            app.$router.push("/activelectures");
          },
          (x) => {
            app.$ns.cast("snack", {
              text: "用户名或密码错误",
              color: "error",
            });
          }
        );
    },
    register(app, vm) {
      Vue.hc
        .req()
        .post("members/register", vm, (x) => {
          Vue.ls.set("token", JSON.stringify(x.Data));
          this.$patch({
            token: x.Data,
          });
        })
        .get("members/profile", (x) => {
          this.$patch({ profile: x.Data });
        })
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "注册成功",
        })
        .subscribe(
          (x) => {
            app.$router.push("/userprofile");
          },
          (x) => {
            app.$ns.cast("snack", {
              text: "用户名或密码错误",
              color: "error",
            });
          }
        );
    },
    logout() {
      Vue.ls.remove("token");
      this.profile = null;
      this.token = null;
    },
    getUserProfile() {
      return Vue.hc
        .req()
        .get("members/profile", (x) => this.$patch({ profile: x.Data }))
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "",
        });
    },
  },
});
