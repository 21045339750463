var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_c('v-btn',{attrs:{"text":"","color":"secondary","to":"/admin/qualifications"}},[_vm._v("返回")]),_c('v-spacer'),_c('small',[_vm._v("考题管理")]),_c('v-spacer'),_c('v-btn',{staticStyle:{"margin-right":"15px"},attrs:{"color":"success","to":("/admin/qualifications/" + (this.id) + "/preview")}},[_vm._v("预览考卷")]),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.openQuizGroupEditDialog()}}},[_vm._v("添加大题")])],1),_c('v-card-text',_vm._l((_vm.data.Groups),function(g,index){return _c('v-card',{key:index,attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"extended":"","color":"blue-grey  lighten-5","dense":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(g.title))]),_c('v-spacer'),_c('v-btn',{staticStyle:{"margin-right":"10px"},attrs:{"color":"primary"},on:{"click":function($event){return _vm.openQuizGroupEditDialog(g)}}},[_vm._v("编辑")]),_c('v-btn',{staticStyle:{"margin-right":"10px"},attrs:{"color":"secondary"},on:{"click":function($event){return _vm.openPickDialog(g.id)}}},[_vm._v("从题库选择题目")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.openQuizEditDialog(
                {
                  type: '单选题',
                  options: '[]',
                  answer: '[]',
                },
                g.id
              )}}},[_vm._v("添加题目")]),_c('template',{slot:"extension"},[_c('span',{domProps:{"innerHTML":_vm._s(g.description)}})])],2),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":g.Quizs,"hide-default-footer":"","disable-pagination":"","dense":"","item-key":"id","fixed-header":true},scopedSlots:_vm._u([{key:"item.op",fn:function(props){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","small":"","icon":""},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.openQuizEditDialog(props.item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v("编辑")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"error","small":"","icon":""},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.deleteQuiz(props.item.id)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("删除考题")])])]}},{key:"no-data",fn:function(){return undefined},proxy:true}],null,true)})],1)],1)}),1)],1),_c('quiz-group-edit-dialog',{ref:"_quizgroup",attrs:{"open":_vm.quizGroupDialog,"qualificationid":_vm.id},on:{"update:open":function($event){_vm.quizGroupDialog=$event},"success":_vm.fetchData}}),_c('qualification-quiz-editor-dialog',{ref:"_quiz",attrs:{"isquizbase":false,"open":_vm.quizDialog},on:{"update:open":function($event){_vm.quizDialog=$event},"success":_vm.fetchData}}),_c('pick-quiz-from-base-dialog',{ref:"_quizbase",attrs:{"qualificationid":_vm.id},on:{"success":_vm.fetchData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }