<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex xs12> 题目 </v-flex>
      <v-flex xs12>
        <vue-editor
          v-model="quizdata.title"
          :editor-toolbar="toolbar"
          placeholder="题目"
        ></vue-editor>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12>
        <v-checkbox
          v-for="(o, index) in quizdata.options"
          :label="o.label"
          :key="index"
          v-model="o.checked"
        >
          <template v-slot:label>
            <span class="quiz-option-label"> {{ o.label }}</span>
            <vue-editor v-model="o.title" :editor-toolbar="toolbar"></vue-editor>
            <v-btn
              class="quiz-option-button"
              v-if="quizdata.options.length > 1"
              @click="deleteOption(o)"
              >删除</v-btn
            >
          </template>
        </v-checkbox>
      </v-flex>
      <v-flex xs1></v-flex>
      <v-flex xs11>
        <v-btn @click="addOption">增加选项</v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  name: "QuizMultipleEditor",
  props: ["quizdata"],
  data() {
    return {
      answer: "",
      rules: {
        title: [(v) => !!v || "请填写考试题目"],
        blank: [(v) => !!v || "请填写答案"],
      },
      toolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["clean"], // remove formatting button
        ["image"],
      ],
    };
  },
  mounted() {
    if (!this.quizdata.options || !this.quizdata.options.length) {
      this.quizdata.options = [
        {
          label: "A",
          title: "",
          checked: false,
        },
        {
          label: "B",
          title: "",
          checked: false,
        },
        {
          label: "C",
          title: "",
          checked: false,
        },
        {
          label: "D",
          title: "",
          checked: false,
        },
      ];
      this.quizdata.answer = [];
      this.quizdata.answer[0] = {
        id: 0,
        label: "单选题",
        answer: "",
      };
      this.answer = "";
    } else {
      this.answer = this.quizdata.answer[0].answer;
    }
  },
  beforeDestroy() {},
  methods: {
    deleteOption(option) {
      var idx = 0;
      for (var i = 0; i < this.quizdata.options.length; i++) {
        if (this.quizdata.options[i].label == option.label) {
          idx = i;
          break;
        }
      }
      this.quizdata.options.splice(idx, 1);
      for (i = 0; i < this.quizdata.options.length; i++) {
        this.quizdata.options[i].label = String.fromCharCode(65 + i);
      }
      this.$forceUpdate();
    },
    addOption() {
      var idx = this.quizdata.options.length;
      this.quizdata.options[idx] = {
        label: String.fromCharCode(idx + 65),
        title: "",
        checked: false,
      };
      this.$forceUpdate();
    },
  },
};
</script>
