import Vue from "vue";
import { of, throwError } from "rxjs";
import { mergeMap, catchError, finalize, tap } from "rxjs/operators";

class HttpClient {
  req() {
    return new ApiRequest(Vue.http, Vue.toasted, Vue.ns);
  }
}

class ApiRequest {
  constructor(client, tr, ns) {
    this.obs = of(null);
    this.client = client;
    this.tr = tr;
    this.ns = ns;
  }
  gotoLogin() {
    window.location.href = "#/";
  }

  post(uri, data, cb = null) {
    this.obs = this.obs.pipe(
      mergeMap((x) => {
        if (x && !x.body.Success) {
          if (!x.body.access_token) {
            return throwError(x.body);
          }
        }
        let url = "";
        if (typeof uri === "string") {
          url = uri;
        } else {
          url = uri(x);
        }
        let d = null;
        if (typeof data === "object") {
          d = data;
        } else {
          d = data(x);
        }
        return this.client.post(url, d);
      })
    );
    if (cb) {
      this.obs = this.obs.pipe(tap((x) => cb(x.body)));
    }
    return this;
  }

  put(uri, data, cb = null) {
    this.obs = this.obs.pipe(
      mergeMap((x) => {
        if (x && !x.body.Success) {
          if (!x.body.access_token) {
            return throwError(x.body);
          }
        }
        let url = "";
        if (typeof uri === "string") {
          url = uri;
        } else {
          url = uri(x);
        }
        let d = null;
        if (typeof data === "object") {
          d = data;
        } else {
          d = data(x);
        }
        return this.client.put(url, d);
      })
    );
    if (cb) {
      this.obs = this.obs.pipe(tap((x) => cb(x.body)));
    }
    return this;
  }

  get(uri, cb = null) {
    this.obs = this.obs.pipe(
      mergeMap((x) => {
        if (x && !x.body.Success) {
          if (!x.body.access_token) {
            return throwError(x.body);
          }
        }
        let url = "";
        if (typeof uri === "string") {
          url = uri;
        } else {
          url = uri(x);
        }
        return this.client.get(url);
      })
    );
    if (cb) {
      this.obs = this.obs.pipe(tap((x) => cb(x.body)));
    }
    return this;
  }

  delete(uri, cb = null) {
    this.obs = this.obs.pipe(
      mergeMap((x) => {
        if (x && !x.body.Success) {
          if (!x.body.access_token) {
            return throwError(x.body);
          }
        }
        let url = "";
        if (typeof uri === "string") {
          url = uri;
        } else {
          url = uri(x);
        }
        return this.client.delete(url);
      })
    );
    if (cb) {
      this.obs = this.obs.pipe(tap((x) => cb(x.body)));
    }
    return this;
  }
  /**
   * start request chain
   *
   * @param {options} {
    lock?: boolean;
    toastError?: boolean;
    toastSuccess?: string;
    final?: () => void;
  }   
   */
  go(options = {}) {
    const defaultOpt = {
      lock: true,
      toastError: true,
      toastSuccess: "",
    };
    if (!options) {
      options = {};
    }
    options = Object.assign(defaultOpt, options);
    this.obs = this.obs.pipe(
      mergeMap((x) => {
        if (x.body.Success || x.body.access_token) {
          if (options.toastSuccess) {
            //this.tr.success(options.toastSuccess);
            this.ns.cast("snack", { text: options.toastSuccess });
          }
          return of(x.body);
        } else {
          return throwError(x.body);
        }
      })
    );

    if (options.lock) {
      this.ns.cast("lock", true);
      this.obs = this.obs.pipe(
        finalize(() => {
          this.ns.cast("lock", false);
          if (options.final) {
            options.final();
          }
        })
      );
    } else {
      this.obs = this.obs.pipe(
        finalize(() => {
          if (options.final) {
            options.final();
          }
        })
      );
    }

    this.obs = this.obs.pipe(
      catchError((err) => {
        var done = false;
        var msg = { Message: "", Details: "" };
        if (err.status === 401) {
          msg.Message = "登录超时，请从新登录系统";
          this.gotoLogin();
        } else if (err.status === 403) {
          msg.Message = "你没有权限这么做";
          done = true;
          this.ns.cast("snack", { text: msg.Message, color: "error" });
        } else {
          msg = err; // JSON.parse(err.message);
        }
        if (options.toastError && !done) {
          //this.tr.error(msg.Message);
          this.ns.cast("snack", { text: msg.Message, color: "error" });
        }
        return throwError(msg);
      })
    );

    return this.obs;
  }
}

export default new HttpClient();
