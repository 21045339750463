<template>
  <v-container fluid style="padding-top: 80px">
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md8 style="padding-top: 100px; padding-bottom: 25px">
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>注册</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" to="/login" icon large slot="activator">
                  <v-icon small>登录</v-icon>
                </v-btn>
              </template>
              <span>已有账户</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text>
            <v-stepper alt-labels v-model="vm.step">
              <v-stepper-header>
                <v-stepper-step :complete="vm.step > 1" step="1">
                  基本信息
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                  :complete="vm.step > 2"
                  step="2"
                  :rules="[() => (vm.step == 3 && !vm.face_feature ? false : true)]"
                >
                  拍照认证
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="vm.step > 3" step="3">
                  确认资料
                </v-stepper-step>
              </v-stepper-header>
              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-card>
                    <v-card-text>
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <v-text-field
                          v-model="vm.sfz"
                          :rules="rules.sfzRules"
                          prepend-icon="person"
                          autocomplete="false"
                          :counter="20"
                          label="账户(身份证号)"
                          type="text"
                          required
                        ></v-text-field>
                        <v-text-field
                          v-model="vm.name"
                          :rules="rules.nameRules"
                          prepend-icon="person"
                          autocomplete="false"
                          :counter="20"
                          label="姓名"
                          type="text"
                          required
                        ></v-text-field>
                        <v-text-field
                          v-model="vm.gongzuodanwei"
                          :rules="rules.danweiRules"
                          prepend-icon="apartment"
                          autocomplete="false"
                          :counter="50"
                          label="工作单位"
                          type="text"
                          required
                        ></v-text-field>
                        <v-text-field
                          v-model="vm.zhiwu"
                          :rules="rules.zhiwuRules"
                          prepend-icon="supervisor_account"
                          autocomplete="false"
                          :counter="20"
                          label="职务"
                          type="text"
                          required
                        ></v-text-field>
                        <v-text-field
                          v-model="vm.mobile"
                          :rules="rules.phoneRules"
                          prepend-icon="phonelink_ring"
                          autocomplete="false"
                          :counter="20"
                          label="联系电话"
                          type="text"
                          required
                        ></v-text-field>
                        <v-text-field
                          v-model="vm.password"
                          autocomplete="new-password"
                          :rules="rules.pass1Rules"
                          prepend-icon="lock"
                          :counter="50"
                          label="密码"
                          type="password"
                        ></v-text-field>
                        <v-text-field
                          v-model="vm.password2"
                          autocomplete="new-password"
                          :rules="[confirmPass]"
                          prepend-icon="lock"
                          :counter="50"
                          label="确认密码"
                          type="password"
                        ></v-text-field>
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" :disabled="!valid" @click="gotostep2(false)"
                        >下一步</v-btn
                      >
                      <v-btn color="primary" @click="gotostep2(true)">test</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-stepper-content>
                <v-stepper-content step="2" style="padding: 20px 5px 20px 5px">
                  <v-card>
                    <v-card-subtitle class="pb-0">
                      该照片将用于管理员对您的账号进行认证,
                      只有经过认证的账号才可正常访问系统资源。 如有以为请咨询管理员。<br />
                      请连接好摄像头, 选择你的摄像头。确保您的面部正对摄像头,
                      然后点击[拍照]按钮。右侧会出现拍摄的照片, 系统正确识别出人脸后,
                      将自动进入下一步。<br />
                      如按钮扔不可用请调整您的面部姿态重新点击[拍照]直至识别成功。如果您现在没有摄像头设备,
                      可点击[跳过]按钮完成注册, 之后可在个人资料页面拍照。
                    </v-card-subtitle>
                    <v-card-text style="padding: 5px">
                      <v-row align="center">
                        <v-col cols="6">
                          <v-select
                            :items="devices"
                            v-model="deviceId"
                            label="选择摄像头"
                            item-text="name"
                            item-value="id"
                            @change="startVideo"
                          ></v-select>
                        </v-col>
                        <v-col cols="6"
                          ><v-btn @click="capture" :disabled="(deviceId || '') == ''"
                            >拍照</v-btn
                          ></v-col
                        >
                      </v-row>

                      <v-row align="center">
                        <v-col cols="6">
                          <video
                            ref="_video"
                            @playing="onVideoPlay"
                            class="video"
                            autoplay
                          ></video>
                        </v-col>
                        <v-col cols="6">
                          <canvas ref="_capture" class="video"></canvas>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                    </v-card-actions>

                    <v-card-actions>
                      <v-btn color="primary" @click="vm.step = 1">上一步</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="gotostep3">跳过</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-stepper-content>

                <v-stepper-content step="3">
                  <v-card>
                    <v-card-text>
                      <v-row>
                        <v-col cols="3"></v-col>
                        <v-col cols="6">
                          <div v-if="vm.face_photh">
                            <img :src="vm.face_photh" class="video" alt="" />
                          </div>
                          <span v-if="!vm.face_photh"
                            >未拍摄照片,
                            注册完成后请尽快去个人资料页面完善个人照片资料</span
                          >
                        </v-col>
                        <v-col cols="3"></v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="2">账户(身份证号):</v-col>
                        <v-col cols="4">{{ vm.sfz }}</v-col>
                        <v-col cols="2">姓名:</v-col>
                        <v-col cols="4">{{ vm.name }}</v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="2">工作单位:</v-col>
                        <v-col cols="4">{{ vm.gongzuodanwei }}</v-col>
                        <v-col cols="2">职务:</v-col>
                        <v-col cols="4">{{ vm.zhiwu }}</v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="2">联系电话:</v-col>
                        <v-col cols="4">{{ vm.mobile }}</v-col>
                        <v-col cols="6"></v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn color="primary" @click="gotostep2(true)">上一步</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="register">确认注册</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { useUserStore } from "@/stores/userStore.js";
export default {
  data: () => ({
    valid: false,

    deviceId: "",
    devices: [],
    vm: {
      step: 1,
      sfz: "",
      name: "",
      password: "",
      password2: "",
      face_feature: "",
      face_photh: "",
    },
    rules: {
      sfzRules: [
        (v) => !!v || "请填写账户(身份证号)",
        (v) =>
          (v && v.length <= 20 && v.length >= 15) ||
          "账户(身份证号)长度必须在15到20个字符之间",
        (v) => /^[0-9]+([a-zA-Z])?$/.test(v) || "账户(身份证号)不正确",
      ],
      nameRules: [
        (v) => !!v || "请填写姓名",
        (v) => (v && v.length <= 5 && v.length >= 1) || "姓名长度必须在1到5个字符之间",
      ],

      danweiRules: [
        (v) => !!v || "请填写工作单位",
        (v) =>
          (v && v.length <= 50 && v.length >= 1) || "工作单位长度必须在1到50个字符之间",
      ],
      zhiwuRules: [
        (v) => !!v || "请填写职务",
        (v) => (v && v.length <= 20 && v.length >= 1) || "职务长度必须在1到20个字符之间",
      ],
      phoneRules: [
        (v) => !!v || "请填写联系电话",
        (v) =>
          (v && v.length <= 50 && v.length >= 8) || "联系电话长度必须在8到50个字符之间",
      ],
      pass1Rules: [
        (v) => !!v || "请填写密码",
        (v) => (v && v.length <= 50 && v.length >= 6) || "密码长度必须在6到50个字符之间",
      ],
    },
  }),
  beforeDestroy() {
    this.closeCamera();
  },
  methods: {
    confirmPass(value) {
      if (!value) return "请填写确认密码";
      return value === this.vm.password || "密码不一致";
    },
    gotostep2(istest) {
      if (!istest && !this.$refs.form.validate()) return;
      this.vm.step = 2;
      this.listDevices();
    },
    gotostep3() {
      this.vm.step = 3;
      this.closeCamera();
    },
    register() {
      if (!this.$refs.form.validate()) return;
      const userStore = useUserStore();
      userStore.register(this, this.vm);
    },

    closeCamera() {
      if (window.__stream) {
        window.__stream.getTracks().forEach((track) => {
          track.stop();
        });
        window.__stream = null;
      }
    },
    startVideo() {
      this.closeCamera();
      var self = this;
      navigator.mediaDevices
        .getUserMedia({
          video: {
            deviceId: { exact: self.deviceId },
          },
          audio: false,
        })
        .then((x) => {
          window.__stream = x;
          self.$refs._video.srcObject = x;
        })
        .catch((error) => {});
    },
    reset() {
      this.devices = [];
      this.deviceId = "";
      this.canSave = false;
      if (this.$refs && this.$refs._capture) {
        this.$refs._capture.width = 0;
        this.$refs._capture.height = 0;
      }
    },
    onVideoPlay() {
      this.$refs._capture.width = this.$refs._video.clientWidth;
      this.$refs._capture.height = this.$refs._video.clientHeight;
    },
    capture() {
      this.$ns.cast("lock", true);
      setTimeout(() => {
        var fapi = this.$face;
        this.$refs._capture
          .getContext("2d")
          .drawImage(
            this.$refs._video,
            0,
            0,
            this.$refs._video.clientWidth,
            this.$refs._video.clientHeight
          );
        this.vm.face_photo = this.$refs._capture.toDataURL("image/jpeg");
        this.$face._api
          .detectSingleFace(this.$refs._capture)
          .withFaceLandmarks()
          .withFaceDescriptor()
          .then((x) => {
            if (x) {
              this.vm.face_feature = fapi.saveFaceFeature(x.descriptor);
              this.gotostep3();
            } else {
              this.$ns.cast("snack", {
                text: "未能识别出人脸, 请调整面部姿态并重试",
                color: "warning",
              });
            }
            this.$ns.cast("lock", false);
          });
      }, 500);
    },
    listDevices() {
      this.reset();
      var self = this;
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: false })
        .then((x) => {
          window.__stream = x;
          navigator.mediaDevices.enumerateDevices().then((devices) => {
            for (var i = 0; i < devices.length; i++) {
              var d = devices[i];
              if (d.deviceId && d.kind == "videoinput") {
                self.devices.push({
                  name: d.label,
                  id: d.deviceId,
                });
              }
            }
            const tracks = x.getTracks();
            tracks.forEach((x) => x.stop());
            window.__stream = null;
          });
        })
        .catch((x) => {});
    },
  },
};
</script>
