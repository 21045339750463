<template>
  <div>
    <v-card>
      <v-card-title>
        <v-btn text color="secondary" to="/admin/qualifications">返回</v-btn>
        <v-spacer></v-spacer>
        <small>考题管理</small>
        <v-spacer></v-spacer>
        <v-btn
          style="margin-right: 15px"
          color="success"
          :to="`/admin/qualifications/${this.id}/preview`"
          >预览考卷</v-btn
        >
        <v-btn color="secondary" @click="openQuizGroupEditDialog()">添加大题</v-btn>
      </v-card-title>
      <v-card-text>
        <v-card v-for="(g, index) in data.Groups" :key="index" outlined>
          <v-toolbar extended color="blue-grey  lighten-5" dense>
            <v-toolbar-title>{{ g.title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              @click="openQuizGroupEditDialog(g)"
              color="primary"
              style="margin-right: 10px"
              >编辑</v-btn
            >
            <v-btn
              @click="openPickDialog(g.id)"
              style="margin-right: 10px"
              color="secondary"
              >从题库选择题目</v-btn
            >
            <v-btn
              @click="
                openQuizEditDialog(
                  {
                    type: '单选题',
                    options: '[]',
                    answer: '[]',
                  },
                  g.id
                )
              "
              color="success"
              >添加题目</v-btn
            >
            <template slot="extension">
              <span v-html="g.description"></span>
            </template>
          </v-toolbar>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="g.Quizs"
              hide-default-footer
              disable-pagination
              dense
              item-key="id"
              :fixed-header="true"
            >
              <template v-slot:item.op="props">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="primary"
                      small
                      icon
                      v-on="on"
                      @click.native.stop="openQuizEditDialog(props.item)"
                    >
                      <v-icon small>edit</v-icon>
                    </v-btn>
                  </template>
                  <span>编辑</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="error"
                      small
                      icon
                      v-on="on"
                      @click.native.stop="deleteQuiz(props.item.id)"
                    >
                      <v-icon small>delete</v-icon>
                    </v-btn>
                  </template>
                  <span>删除考题</span>
                </v-tooltip>
              </template>
              <template v-slot:no-data></template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
    <quiz-group-edit-dialog
      ref="_quizgroup"
      :open.sync="quizGroupDialog"
      :qualificationid="id"
      @success="fetchData"
    ></quiz-group-edit-dialog>
    <qualification-quiz-editor-dialog
      ref="_quiz"
      :isquizbase="false"
      :open.sync="quizDialog"
      @success="fetchData"
    ></qualification-quiz-editor-dialog>
    <pick-quiz-from-base-dialog
      ref="_quizbase"
      @success="fetchData"
      :qualificationid="id"
    ></pick-quiz-from-base-dialog>
  </div>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      loading: true,
      data: {},
      quizDialog: false,
      quizGroupDialog: false,
      quiz: {},
      headers: [
        {
          text: "序号",
          value: "ordindex",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "题目",
          value: "title2",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "类型",
          value: "type",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "分值",
          value: "point",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "操作",
          value: "op",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
      ],
    };
  },
  watch: {
    id: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    confirmTo() {},
    confirmEnd() {},
    search() {
      this.fetchData();
    },
    fetchData() {
      this.loading = true;
      this.$hc
        .req()
        .get(`admin/qualificationquizs?qid=${this.id}`)
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.data = x.Data;
          },
          (x) => {}
        );
    },
    openQuizEditDialog(data, groupid) {
      if (groupid) {
        data.qualificationgroupid = groupid;
      }
      this.$refs._quiz.loadData(data);
      this.quizDialog = true;
    },
    openQuizGroupEditDialog(g) {
      this.$refs._quizgroup.loadData(g);
      this.quizGroupDialog = true;
    },
    openPickDialog(groupid) {
      this.$refs._quizbase.openDialog(groupid);
    },
    deleteQuiz(id) {
      this.$confirm("确定要删除吗? 该操作无法恢复").then((x) => {
        if (x) {
          this.$hc
            .req()
            .delete(`admin/qualificationquizs?id=${id}`)
            .go({
              lock: true,
              toastError: true,
              toastSuccess: "操作成功",
            })
            .subscribe(
              (x) => {
                this.fetchData();
              },
              (x) => {}
            );
        }
      });
    },
  },
};
</script>
