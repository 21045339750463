var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_c('small',[_vm._v("题库管理")]),_c('v-spacer'),_c('v-text-field',{staticClass:"ma-2",staticStyle:{"max-width":"150px"},attrs:{"label":"题目","dense":"","hide-details":"","autocomplete":"false"},model:{value:(_vm.q.title),callback:function ($$v) {_vm.$set(_vm.q, "title", $$v)},expression:"q.title"}}),_c('v-text-field',{staticClass:"ma-2",staticStyle:{"max-width":"150px"},attrs:{"label":"类别","dense":"","hide-details":"","autocomplete":"false"},model:{value:(_vm.q.category),callback:function ($$v) {_vm.$set(_vm.q, "category", $$v)},expression:"q.category"}}),_c('v-select',{staticStyle:{"max-width":"100px"},attrs:{"items":_vm.quizType,"label":"题型","item-text":"label","item-value":"value","dense":"","hide-details":""},model:{value:(_vm.q.type),callback:function ($$v) {_vm.$set(_vm.q, "type", $$v)},expression:"q.type"}}),_c('v-select',{staticStyle:{"max-width":"100px"},attrs:{"items":_vm.diffcultyType,"label":"难度","item-text":"label","item-value":"value","dense":"","hide-details":""},model:{value:(_vm.q.diffculty),callback:function ($$v) {_vm.$set(_vm.q, "diffculty", $$v)},expression:"q.diffculty"}}),_c('v-btn',{attrs:{"icon":"","color":"blue"},on:{"click":_vm.fetchData}},[_c('v-icon',[_vm._v("search")])],1),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"success"},on:{"click":_vm.openImportDialog}},[_vm._v("批量导入")]),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.openQuizEditDialog({
            type: '单选题',
            options: '[]',
            answer: '[]',
          })}}},[_vm._v("添加")])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data.Payload,"loading":_vm.loading,"hide-default-footer":"","disable-pagination":"","item-key":"id","fixed-header":true},scopedSlots:_vm._u([{key:"item.op",fn:function(props){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","small":"","icon":""},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.openQuizEditDialog(props.item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v("编辑")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"error","small":"","icon":""},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.deleteQuiz(props.item.id)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("删除考题")])])]}},{key:"no-data",fn:function(){return undefined},proxy:true}])})],1),_c('div',{staticClass:"text-xs-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pages,"total-visible":10},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)],1),_c('qualification-quiz-editor-dialog',{ref:"_quiz",attrs:{"isquizbase":true,"open":_vm.quizDialog},on:{"update:open":function($event){_vm.quizDialog=$event},"success":_vm.fetchData}}),_c('import-quiz-base-dialog',{ref:"_import",on:{"success":_vm.fetchData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }