<template>
  <div>
    <v-card>
      <v-card-title>
        <small>题库管理</small>
        <v-spacer></v-spacer>
        <v-text-field
          label="题目"
          v-model="q.title"
          dense
          hide-details=""
          autocomplete="false"
          class="ma-2"
          style="max-width: 150px"
        ></v-text-field>
        <v-text-field
          label="类别"
          v-model="q.category"
          dense
          hide-details=""
          class="ma-2"
          autocomplete="false"
          style="max-width: 150px"
        ></v-text-field>
        <v-select
          :items="quizType"
          v-model="q.type"
          label="题型"
          item-text="label"
          item-value="value"
          dense
          style="max-width: 100px"
          hide-details=""
        ></v-select>
        <v-select
          :items="diffcultyType"
          v-model="q.diffculty"
          label="难度"
          item-text="label"
          item-value="value"
          dense
          style="max-width: 100px"
          hide-details=""
        ></v-select>
        <v-btn icon color="blue" @click="fetchData">
          <v-icon>search</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-btn color="success" class="mr-2" @click="openImportDialog">批量导入</v-btn>
        <v-btn
          color="secondary"
          @click="
            openQuizEditDialog({
              type: '单选题',
              options: '[]',
              answer: '[]',
            })
          "
          >添加</v-btn
        >
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="data.Payload"
          :loading="loading"
          hide-default-footer
          disable-pagination
          item-key="id"
          :fixed-header="true"
        >
          <template v-slot:item.op="props">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  small
                  icon
                  v-on="on"
                  @click.native.stop="openQuizEditDialog(props.item)"
                >
                  <v-icon small>edit</v-icon>
                </v-btn>
              </template>
              <span>编辑</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="error"
                  small
                  icon
                  v-on="on"
                  @click.native.stop="deleteQuiz(props.item.id)"
                >
                  <v-icon small>delete</v-icon>
                </v-btn>
              </template>
              <span>删除考题</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data></template>
        </v-data-table>
      </v-card-text>
      <div class="text-xs-center pt-2">
        <v-pagination
          v-model="pagination.page"
          :length="pages"
          :total-visible="10"
        ></v-pagination>
      </div>
    </v-card>
    <qualification-quiz-editor-dialog
      ref="_quiz"
      :isquizbase="true"
      :open.sync="quizDialog"
      @success="fetchData"
    ></qualification-quiz-editor-dialog>
    <import-quiz-base-dialog ref="_import" @success="fetchData"></import-quiz-base-dialog>
  </div>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      loading: true,
      data: {},
      quizDialog: false,
      quiz: {},
      q: {
        title: "",
        category: "",
        type: "",
        diffculty: "",
      },
      pagination: {
        page: 1,
        rowsPerPage: 15,
      },
      quizType: [
        { label: "不限", value: "" },
        { label: "单选题", value: "单选题" },
        { label: "多选题", value: "多选题" },
        { label: "填空题", value: "填空题" },
        { label: "判断题", value: "判断题" },
        { label: "问答题", value: "问答题" },
        { label: "单选题", value: "单选题" },
      ],
      diffcultyType: [
        { label: "不限", value: "" },
        { label: "简单", value: "简单" },
        { label: "中等", value: "中等" },
        { label: "困难", value: "困难" },
        { label: "极难", value: "极难" },
      ],
      headers: [
        {
          text: "ID",
          value: "id",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "题目",
          value: "title2",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "类型",
          value: "type",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "类别",
          value: "category",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "难度",
          value: "diffculty",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "分值",
          value: "point",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "操作",
          value: "op",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
      ],
    };
  },
  watch: {
    id: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },
    pagination: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },
  },
  computed: {
    pages() {
      if (this.data.Total) {
        return Math.ceil(this.data.Total / this.pagination.rowsPerPage);
      }
      return 0;
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    search() {
      this.fetchData();
    },
    openImportDialog() {
      this.$refs._import.openDialog();
    },
    fetchData() {
      this.loading = true;
      this.$hc
        .req()
        .get(
          `admin/qualificationquizbase?pagesize=${
            this.pagination.rowsPerPage
          }&pageindex=${this.pagination.page}&title=${this.q.title || ""}&category=${
            this.q.category || ""
          }&type=${this.q.type || ""}&diffculty=${this.q.diffculty || ""}`
        )
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.data = x.Data;
          },
          (x) => {}
        );
    },
    openQuizEditDialog(data, isnew) {
      this.$refs._quiz.loadData(data, isnew);
      this.quizDialog = true;
    },
    deleteQuiz(id) {
      this.$confirm("确定要删除吗? 该操作无法恢复").then((x) => {
        if (x) {
          this.$hc
            .req()
            .delete(`admin/qualificationquizbase?id=${id}`)
            .go({
              lock: true,
              toastError: true,
              toastSuccess: "操作成功",
            })
            .subscribe(
              (x) => {
                this.fetchData();
              },
              (x) => {}
            );
        }
      });
    },
  },
};
</script>
