<template>
  <v-dialog v-model="open" persistent max-width="600px">
    <v-form ref="form" v-model="valid" lazy-validation autocomplete="random-string">
      <v-card>
        <v-card-title>
          <span class="headline">导入题库</span>
          <v-spacer></v-spacer>
          <a href="data/template.xls" target="_blank">下载模板</a>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-file-input
                  label="选择Excel文件"
                  v-model="vm.file"
                  accept=".xls,.xlsx"
                  :rules="rules.excel"
                ></v-file-input>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="open = false">取消</v-btn>
          <v-btn color="warning" :disabled="!valid" @click="upload">导入</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: "ImportQuizBaseDialog",
  props: [],
  data() {
    return {
      valid: false,
      open: false,
      vm: {},
      rules: {
        excel: [(v) => !!v || "请选择Excel文件"],
      },
    };
  },

  methods: {
    openDialog() {
      this.file = "";
      this.open = true;
    },
    upload() {
      if (!this.$refs.form.validate()) return;

      var fe = new FormData();
      fe.append("file", this.vm.file);
      this.$hc
        .req()
        .post(`admin/qualificationquizbase/import`, fe)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "操作成功",
        })
        .subscribe(
          (x) => {
            this.$emit("success");
            this.open = false;
          },
          (x) => {}
        );
    },
  },
};
</script>
