<template>
  <v-dialog v-model="open" persistent max-width="75%">
    <v-card>
      <v-card-title>
        <small>添加考生 (已选择 {{ selectedCount }} 个)</small>
        <v-spacer></v-spacer>
        <v-text-field
          label="姓名"
          v-model="q.Q"
          dense
          hide-details=""
          autocomplete="false"
          class="ma-2"
          style="max-width: 150px"
        ></v-text-field>

        <v-btn icon color="blue" @click="fetchData">
          <v-icon>search</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="data.Payload"
          :loading="loading"
          hide-default-footer
          disable-pagination
          item-key="id"
        >
          <template v-slot:item.op="props">
            <v-checkbox
              style="padding: 0px; margin: 0px"
              hide-details
              v-model="selectedMembers[props.item.id]"
            ></v-checkbox>
          </template>
          <template v-slot:item.isvalid="props">
            <span>{{ props.item.isvalid ? "是" : "否" }}</span>
          </template>
          <template v-slot:no-data></template>
        </v-data-table>
      </v-card-text>
      <div class="text-xs-center pt-2">
        <v-pagination
          v-model="pagination.page"
          :length="pages"
          :total-visible="10"
        ></v-pagination>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="open = false">取消</v-btn>
        <v-btn color="success" @click="addToQualification">添加选中考生</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AddQualificationMembersDialog",
  props: [],
  data() {
    return {
      loading: true,
      open: false,
      data: {},
      groupid: 0,
      qualificationid: 0,
      quizDialog: false,
      selectedMembers: {},
      q: { Q: "" },
      pagination: {
        page: 1,
        rowsPerPage: 10,
      },

      headers: [
        {
          text: "ID",
          value: "id",
          align: "center",
          sortable: false,
          class: "data-table-header",
          width: "100px",
        },
        {
          text: "姓名",
          value: "xingming",
          align: "center",
          sortable: false,
          class: "data-table-header text-hide-overflow-dot",
        },
        {
          text: "性别",
          value: "xingbie",
          align: "center",
          sortable: false,
          class: "data-table-header",
          width: "100px",
        },
        {
          text: "职务",
          value: "zhiwu",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "通过审核",
          value: "isvalid",
          align: "center",
          sortable: false,
          class: "data-table-header",
          width: "100px",
        },
        {
          text: "选择",
          value: "op",
          align: "center",
          sortable: false,
          class: "data-table-header",
          width: "10px",
        },
      ],
    };
  },
  watch: {
    id: {
      handler() {
        if (this.open) {
          this.fetchData();
        }
      },
      deep: true,
    },
    pagination: {
      handler() {
        if (this.open) {
          this.fetchData();
        }
      },
      deep: true,
    },
  },
  computed: {
    pages() {
      if (this.data && this.data.Total) {
        return Math.ceil(this.data.Total / this.pagination.rowsPerPage);
      }
      return 0;
    },
    selectedCount() {
      if (open) {
        var ps = Object.getOwnPropertyNames(this.selectedMembers).filter(
          (x) => !isNaN(x * 1) && this.selectedMembers[x]
        );
        return ps.length;
      }
      return "0";
    },
  },
  methods: {
    openDialog(qualificationid) {
      this.qualificationid = qualificationid;
      this.q.Q = "";
      this.pagination = {
        page: 1,
        rowsPerPage: 10,
      };
      this.selectedMembers = {};
      this.open = true;
      this.fetchData();
    },
    search() {
      this.fetchData();
    },
    fetchData() {
      this.loading = true;
      this.$hc
        .req()
        .get(
          `admin/members/search/qualificationmember?qid=${
            this.qualificationid
          }&pagesize=${this.pagination.rowsPerPage}&pageindex=${this.pagination.page}&Q=${
            this.q.Q || ""
          }`
        )
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.data = x.Data;
          },
          (x) => {}
        );
    },
    addToQualification() {
      this.loading = true;
      var ps = Object.getOwnPropertyNames(this.selectedMembers).filter(
        (x) => !isNaN(x * 1) && this.selectedMembers[x]
      );
      if (!ps.length) {
        return;
      }
      this.$hc
        .req()
        .post(`admin/qualificationmembers/AddMembers`, {
          qualificationid: this.qualificationid,
          memberids: ps,
        })
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.$emit("success");
            this.open = false;
          },
          (x) => {}
        );
    },
  },
};
</script>
