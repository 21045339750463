<template>
  <div>
    <v-card>
      <v-card-title>
        <small>在线考试</small>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="data.Payload"
          :loading="loading"
          hide-default-footer
          :server-items-length="data.Total"
          item-key="id"
          :fixed-header="true"
        >
          <template v-slot:item.start="props">{{
            props.item.start | moment("YYYY-MM-DD HH:mm")
          }}</template>
          <template v-slot:item.end="props">{{
            props.item.end | moment("YYYY-MM-DD HH:mm")
          }}</template>
          <template v-slot:item.score="props">
            <span>{{ props.item.status == "成绩发布" ? props.item.score : "" }}</span>
          </template>
          <template v-slot:item.enter="props">
            <v-btn
              v-if="props.item.canenter"
              x-small
              class="success"
              @click="openValidDialog(props.item)"
              >进入考试</v-btn
            >
            <v-btn
              v-if="props.item.canmonitor"
              x-small
              class="success"
              :to="`/qualifications/${props.item.id}/monitor`"
              >进入监考</v-btn
            >
            <v-btn
              v-if="props.item.canverify"
              x-small
              class="success"
              :to="`/qualifications/${props.item.id}/members`"
              >进入判卷</v-btn
            >
          </template>
          <template v-slot:no-data></template>
        </v-data-table>
      </v-card-text>
      <div class="text-xs-center pt-2">
        <v-pagination
          v-model="pagination.page"
          :length="pages"
          :total-visible="10"
        ></v-pagination>
      </div>
    </v-card>

    <join-qualification-dialog
      ref="_jd"
      :data="qualification"
      :open.sync="joinDialog"
      @success="fetchData"
    ></join-qualification-dialog>
    <valid-qualification-dialog
      ref="_enter"
      :data="qualification"
      :open.sync="validDialog"
      @success="fetchData"
    ></valid-qualification-dialog>
  </div>
</template>

<script>
import { useUserStore } from "@/stores/userStore.js";
export default {
  data() {
    return {
      loading: true,
      joinDialog: false,
      validDialog: false,
      qualification: {},
      pagination: {
        page: 1,
        rowsPerPage: 20,
      },
      data: {},

      headers: [
        {
          text: "考试名称",
          value: "name",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "状态",
          value: "status",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "考试开始",
          value: "start",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "考试结束",
          value: "end",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "考试时长",
          value: "duration",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
        {
          text: "成绩",
          value: "score",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },

        {
          text: "考试",
          value: "enter",
          align: "center",
          sortable: false,
          class: "data-table-header",
        },
      ],
    };
  },
  watch: {
    id: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },
    pagination: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },
  },
  mounted() {
    const userStore = useUserStore();
    userStore.header_tab = 2;
    this.fetchData();
  },

  computed: {
    pages() {
      if (this.data.Total) {
        return Math.ceil(this.data.Total / this.pagination.rowsPerPage);
      }
      return 0;
    },
  },
  methods: {
    confirmTo() {},
    confirmEnd() {},
    search() {
      this.fetchData();
    },
    openJoinDialog(q) {
      if (q.camera_shoot) {
        this.qualification = q;
        this.joinDialog = true;
        this.$refs._jd.listDevices();
      } else {
        this.$hc
          .req()
          .post(`qualifications/join`, { qualificationid: q.id })
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "报名成功",
          })
          .subscribe(
            (x) => {
              this.fetchData();
            },
            (x) => {}
          );
      }
    },
    openValidDialog(q) {
      if (q.camera_shoot) {
        this.qualification = q;
        this.validDialog = true;
        this.$refs._enter.listDevices();
      } else {
        this.$router.push(`/qualifications/${q.id}/start`);
      }
    },
    fetchData() {
      this.loading = true;
      this.$hc
        .req()
        .get(
          `qualifications?pagesize=${this.pagination.rowsPerPage}&pageindex=${this.pagination.page}`
        )
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.data = x.Data;
          },
          (x) => {}
        );
    },
  },
};
</script>
