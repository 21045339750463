<template>
  <div>
    <div :class="goout">
      <v-card style="margin-top: 25px">
        <v-card-title>
          <span :class="cd">{{ countDown.text }}</span>
        </v-card-title>
        <v-card-subtitle class="pb-0">
          <span v-if="qualification.camera || qualification.camera_shoot">
            本次考试需要考生开启摄像头, 请连接并选择您的摄像头, 使摄像头画面出现在下方
            <br
          /></span>

          <span v-if="qualification.recorddesktop">
            本次考试需要录制考生桌面, 请点击[选择桌面], 选择<span class="red--text"
              >共享整个屏幕</span
            >
            使桌面画面出现在下方 <br
          /></span>
          <span>考试开始后,点击[我已调试好设备, 进入考试]按钮开始答题</span>
        </v-card-subtitle>

        <v-card-text>
          <v-row align="center">
            <v-col cols="6" v-if="qualification.camera || qualification.camera_shoot">
              <v-select
                :items="cameras"
                v-model="cameraId"
                label="选择摄像头"
                item-text="name"
                item-value="id"
                @change="startCameraVideo"
              ></v-select>
            </v-col>
            <v-col cols="6" v-if="qualification.recorddesktop">
              <v-btn @click="startDesktopVideo">选择桌面</v-btn>
            </v-col>
          </v-row>

          <v-row align="center">
            <v-col cols="6" v-if="qualification.camera || qualification.camera_shoot">
              <video
                ref="_video_camera"
                @playing="onVideoCameraPlay"
                class="video"
                autoplay
              ></video>
              <canvas ref="_capture_camera" class="video" style="display: none"></canvas>
            </v-col>
            <v-col cols="6" v-if="qualification.recorddesktop">
              <video ref="_video_desktop" class="video" autoplay></video>
            </v-col>
          </v-row>
          <v-row align="center" class="goout">
            <v-col cols="12" v-if="qualification.recorddesktop">
              <video
                ref="_video_desktop1"
                @playing="onVideoDesktopPlay"
                class="video800"
                autoplay
              ></video>
              <canvas
                ref="_capture_desktop"
                class="video800"
                style="display: none"
              ></canvas>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="success" :disabled="!canStar" @click="enterQuiz"
            >我已调试好设备, 进入考试</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { useUserStore } from "@/stores/userStore.js";
export default {
  name: "QualificationPrepair",
  props: ["data", "open"],
  data() {
    return {
      goout: "",
      cd: "",
      valid: false,
      canSave: false,
      canStar: false,
      started: false,
      cameraId: "",
      cameras: [],
      qualification: {},
      sessionid: "",
      countDown: {
        text: "",
        seconds: 0,
        total: 0,
      },
      recorder: {
        camera: {},
        desktop: {},
      },
      face_interval: "",
    };
  },
  mounted() {},
  computed: {
    ...mapState(useUserStore, ["profile"]),
  },
  beforeDestroy() {
    if (this.countDown && this.countDown.id) {
      clearInterval(this.countDown.id);
    }
    if (this.face_interval) {
      clearInterval(this.face_interval);
    }
    document.removeEventListener("visibilitychange", this.postDesktopCapture, true);

    this.closeAll();
  },
  methods: {
    init(data) {
      var self = this;
      this.qualification = data;
      var key_time = `${this.qualification.qid}-taken`;
      this.countDown.total = data.duration * 60;
      this.listCameras();
      this.sessionid = this.$uuid.v4();
      this.countDown.id = setInterval(() => {
        self.countDown.seconds = self.$ls.get(key_time) * 1 || 0;
        if (self.started) {
          self.countDown.seconds++;
          var seconds = Math.abs(self.countDown.total - self.countDown.seconds);
          self.countDown.h = Math.floor((seconds % (60 * 60 * 24)) / (60 * 60));
          self.countDown.m = Math.floor((seconds % (60 * 60)) / 60);
          self.countDown.s = Math.floor(seconds % 60);
          self.countDown.text = `距考试结束 ${(self.countDown.h + "").padStart(
            2,
            "0"
          )}:${(self.countDown.m + "").padStart(2, "0")}:${(
            self.countDown.s + ""
          ).padStart(2, "0")}`;
          self.$ls.set(key_time, self.countDown.seconds);
          if (seconds <= 0) {
            this.closeAll();
            this.$emit("onfinish");
            clearInterval(this.countDown.id);
          }
        } else {
          var left = Math.abs(self.countDown.total - self.countDown.seconds);
          self.countDown.h = Math.floor((left % (60 * 60 * 24)) / (60 * 60));
          self.countDown.m = Math.floor((left % (60 * 60)) / 60);
          self.countDown.s = Math.floor(left % 60);
          self.countDown.text = `准备考试 (剩余时间:${(self.countDown.h + "").padStart(
            2,
            "0"
          )}:${(self.countDown.m + "").padStart(2, "0")}:${(
            self.countDown.s + ""
          ).padStart(2, "0")})`;
        }
        var canStar = true;
        if (self.qualification.recorddesktop) {
          if (!window.__stream_desktop) {
            canStar = false;
          }
        }
        if (self.qualification.camera || self.qualification.camera_shoot) {
          if (!window.__stream_camera) {
            canStar = false;
          }
        }
        this.canStar = canStar;
      }, 1000);
      document.addEventListener("visibilitychange", this.postDesktopCapture, true);
    },
    enterQuiz() {
      this.started = true;
      this.goout = "goout";
      this.cd = "counter";
      this.recordCamera();
      this.recordDesktop();
      this.startFaceInterval();
      this.$emit("onstart");
    },
    onVideoCameraPlay() {
      this.$refs._capture_camera.width = this.$refs._video_camera.clientWidth;
      this.$refs._capture_camera.height = this.$refs._video_camera.clientHeight;
    },
    onVideoDesktopPlay() {
      this.$refs._capture_desktop.width = this.$refs._video_desktop1.clientWidth;
      this.$refs._capture_desktop.height = this.$refs._video_desktop1.clientHeight;
    },
    startFaceInterval() {
      if (this.qualification.camera_shoot) {
        this.face_interval = setInterval(() => {
          this.captureCamera();
        }, this.qualification.camera_shoot_interval * 1000);
      }
    },
    captureCamera() {
      var faceMatcher = this.$face.loadFaceMatcher(this.profile.face_feature);
      this.$refs._capture_camera
        .getContext("2d")
        .drawImage(
          this.$refs._video_camera,
          0,
          0,
          this.$refs._video_camera.clientWidth,
          this.$refs._video_camera.clientHeight
        );
      this.$face._api
        .detectSingleFace(this.$refs._capture_camera)
        .withFaceLandmarks()
        .withFaceDescriptor()
        .then((x) => {
          var img = "";
          if (x) {
            var matchResult = faceMatcher.findBestMatch(x.descriptor);
            if (!matchResult || matchResult.label != "my") {
              img = this.$refs._capture_camera.toDataURL("image/jpeg");
              this.postFaceCapture(img);
            }
          } else {
            img = this.$refs._capture_camera.toDataURL("image/jpeg");
            this.postFaceCapture(img);
          }
        });
    },
    captureDesktop() {
      if (!this.qualification.recorddesktop) {
        return "";
      }
      this.$refs._capture_desktop
        .getContext("2d")
        .drawImage(
          this.$refs._video_desktop1,
          0,
          0,
          this.$refs._video_desktop1.clientWidth,
          this.$refs._video_desktop1.clientHeight
        );
      return this.$refs._capture_desktop.toDataURL("image/jpeg");
    },
    listCameras() {
      if (!(this.qualification.camera || this.qualification.camera_shoot)) {
        return;
      }
      var self = this;
      var cameraId = this.$ls.get("deviceId");
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: false })
        .then((x) => {
          window.__stream_camera = x;
          navigator.mediaDevices.enumerateDevices().then((devices) => {
            for (var i = 0; i < devices.length; i++) {
              var d = devices[i];
              if (d.deviceId && d.kind == "videoinput") {
                self.cameras.push({
                  name: d.label,
                  id: d.deviceId,
                });
              }
            }
            const tracks = x.getTracks();
            tracks.forEach((x) => x.stop());
            window.__stream_camera = null;
            if (cameraId) {
              self.cameraId = cameraId;
              self.startCameraVideo(cameraId);
            }
          });
        })
        .catch((x) => {});
    },

    closeAll() {
      if (this.recorder.desktop.mr) {
        this.recorder.desktop.mr.stop();
        this.recorder.desktop.mr = null;
      }
      if (this.recorder.camera.mr) {
        this.recorder.camera.mr.stop();
        this.recorder.camera.mr = null;
      }
      if (window.__stream_camera) {
        window.__stream_camera.getTracks().forEach((track) => {
          track.stop();
        });
        window.__stream_camera = null;
      }
      if (window.__stream_desktop) {
        window.__stream_desktop.getTracks().forEach((track) => {
          track.stop();
        });
        window.__stream_desktop = null;
      }
    },
    startCameraVideo(deviceId) {
      if (!(this.qualification.camera || this.qualification.camera_shoot)) {
        return;
      }
      if (window.__stream_camera) {
        window.__stream_camera.getTracks().forEach((track) => {
          track.stop();
        });
        window.__stream_camera = null;
      }
      var self = this;
      navigator.mediaDevices
        .getUserMedia({
          video: {
            deviceId: { exact: deviceId },
          },
          audio: false,
        })
        .then((x) => {
          window.__stream_camera = x;
          self.$refs._video_camera.srcObject = x;
        })
        .catch((error) => {});
    },
    startDesktopVideo() {
      if (!this.qualification.recorddesktop) {
        return;
      }
      if (window.__stream_desktop) {
        window.__stream_desktop.getTracks().forEach((track) => {
          track.stop();
        });
        window.__stream_desktop = null;
      }
      var self = this;
      navigator.mediaDevices
        .getDisplayMedia()
        .then((x) => {
          window.__stream_desktop = x;
          self.$refs._video_desktop.srcObject = x;
          self.$refs._video_desktop1.srcObject = x;
        })
        .catch((x) => {});
    },
    postDesktopCapture() {
      var self = this;
      if (document.visibilityState == "hidden") {
        setTimeout(() => {
          var imgData = self.captureDesktop();
          var fe = new FormData();
          fe.append("type", "image_desktop");
          fe.append("status", "切屏");
          fe.append("qualificationid", self.qualification.qid);
          fe.append("message", "切屏");
          fe.append("sessionid", self.sessionid);
          fe.append("image", imgData);
          self.$hc
            .req()
            .post(`qualifications/logs`, fe)
            .go({
              lock: false,
              toastError: false,
              toastSuccess: "",
            })
            .subscribe(
              (x) => {},
              (x) => {}
            );
        }, 1000);
      }
    },
    postFaceCapture(imgData) {
      var fe = new FormData();
      fe.append("type", "image_face");
      fe.append("status", "面部识别失败");
      fe.append("qualificationid", this.qualification.qid);
      fe.append("message", "面部识别失败");
      fe.append("sessionid", this.sessionid);
      fe.append("image", imgData);
      this.$hc
        .req()
        .post(`qualifications/logs`, fe)
        .go({
          lock: false,
          toastError: false,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {},
          (x) => {}
        );
    },
    recordDesktop() {
      if (!this.qualification.recorddesktop) {
        return;
      }
      var self = this;
      this.recorder.desktop.mr = new MediaRecorder(window.__stream_desktop, {
        audioBitsPerSecond: 0,
        videoBitsPerSecond: this.qualification.video_bit_rate,
        mimeType: "video/webm",
      });
      this.recorder.desktop.mr.start(60000);
      this.recorder.desktop.mr.ondataavailable = (e) => {
        var file = new File([e.data], "1.webm", {
          type: "video/webm",
        });
        var fe = new FormData();
        fe.append("type", "video_desktop");
        fe.append("status", "桌面视频");
        fe.append("qualificationid", self.qualification.qid);
        fe.append("message", "桌面视频");
        fe.append("sessionid", self.sessionid);
        fe.append("video", file);
        self.$hc
          .req()
          .post(`qualifications/logs`, fe)
          .go({
            lock: false,
            toastError: false,
            toastSuccess: "",
          })
          .subscribe(
            (x) => {},
            (x) => {}
          );
      };
    },
    recordCamera() {
      if (!this.qualification.camera) {
        return;
      }
      var self = this;
      this.recorder.camera.mr = new MediaRecorder(window.__stream_camera, {
        audioBitsPerSecond: 0,
        videoBitsPerSecond: this.qualification.video_bit_rate,
        mimeType: "video/webm",
      });
      this.recorder.camera.mr.start(60000);
      this.recorder.camera.mr.ondataavailable = (e) => {
        var file = new File([e.data], "1.webm", {
          type: "video/webm",
        });
        var fe = new FormData();
        fe.append("type", "video_face");
        fe.append("status", "摄像头视频");
        fe.append("qualificationid", self.qualification.qid);
        fe.append("message", "摄像头视频");
        fe.append("sessionid", self.sessionid);
        fe.append("video", file);
        self.$hc
          .req()
          .post(`qualifications/logs`, fe)
          .go({
            lock: false,
            toastError: false,
            toastSuccess: "",
          })
          .subscribe(
            (x) => {},
            (x) => {}
          );
      };
    },
  },
};
</script>
<style scoped>
.goout {
  position: absolute;
  right: 100000px;
}
.video {
  max-width: 400px;
  max-height: 400px;
}
.video800 {
  max-width: 2560px;
  max-height: 1192px;
}
.counter {
  position: fixed;
  bottom: 0px;
  z-index: 999;
  right: 100px;
}
</style>
