<template>
  <v-dialog v-model="open" persistent max-width="1000px">
    <v-form ref="form" v-model="valid" lazy-validation autocomplete="random-string">
      <v-card>
        <v-card-title>
          <span class="headline">{{ title }}</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs3>
                <v-select
                  :items="quizType"
                  v-model="data.type"
                  label="题型"
                  :rules="rules.type"
                ></v-select>
              </v-flex>
              <v-flex xs3>
                <v-text-field
                  label="类别"
                  v-model="data.category"
                  autocomplete="false"
                ></v-text-field>
              </v-flex>
              <v-flex xs3>
                <v-select
                  :items="diffcultyType"
                  v-model="data.diffculty"
                  label="难度"
                ></v-select>
              </v-flex>
              <v-flex xs3>
                <v-text-field
                  label="分数"
                  v-model="data.point"
                  :rules="rules.point"
                  autocomplete="false"
                ></v-text-field>
              </v-flex>
              <v-flex xs3 v-if="!isquizbase">
                <v-text-field
                  label="序号"
                  v-model="data.ordindex"
                  :rules="rules.ordindex"
                  autocomplete="false"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
          <quiz-editor v-if="open" :quizdata="data"></quiz-editor>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="$emit('update:open', false)">取消</v-btn>
          <v-btn color="warning" :disabled="!valid" @click="addOrUpdate">保存</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import QuizEditor from "../controls/QuizEditor.vue";
export default {
  components: { QuizEditor },
  name: "QualificationQuizEditorDialog",
  props: ["open", "isquizbase"],
  data() {
    return {
      data: {
        title: "",
        options: [],
        answer: [],
      },
      initializing: false,
      quizType: ["单选题", "多选题", "填空题", "判断题", "问答题"],
      diffcultyType: ["简单", "中等", "困难", "极难"],
      valid: false,
      dialogSet: {},
      rules: {
        title: [(v) => !!v || "请填写考试题目"],
        blank: [(v) => !!v || "请填写答案"],
        option: [(v) => !!v || "请填写选项"],
        ordindex: [(v) => !!v || "请填写序号", (v) => /\d+/.test(v) || "请填写数字"],
        type: [(v) => !!v || "请选择题型"],
        point: [(v) => !!v || "请填写分数", (v) => /\d+/.test(v) || "请填写数字"],
      },
    };
  },
  watch: {
    "data.type": {
      handler() {
        if (!this.initializing) {
          this.data.title = "";
          this.data.options = [];
          this.data.answer = [];
        }
      },
    },
  },
  computed: {
    title() {
      if (this.data && this.data.id) {
        return "编辑题目";
      }
      return "新建题目";
    },
  },
  methods: {
    loadData(data) {
      this.initializing = true;
      var d = { ...data };
      if (d.options) {
        d.options = JSON.parse(d.options);
      }
      if (d.answer) {
        d.answer = JSON.parse(d.answer);
      }
      this.data = d;
      if (!this.data.diffculty) {
        this.data.diffculty = "简单";
      }
      this.$nextTick(() => {
        this.$forceUpdate();
        this.initializing = false;
      });
    },

    addOrUpdate() {
      var url = this.isquizbase
        ? "admin/qualificationquizbase"
        : "admin/qualificationquizs";
      if (!this.$refs.form.validate()) return;
      var data = { ...this.data };
      data.options = JSON.stringify(this.data.options);
      if (this.data.type === "多选题") {
        var ans = [];
        for (var i = 0; i < this.data.options.length; i++) {
          var o = this.data.options[i];
          if (o.checked) {
            ans.push({
              id: i + 1,
              label: "多选题",
              answer: o.label,
            });
          }
        }
        data.answer = JSON.stringify(ans);
      } else {
        data.answer = JSON.stringify(this.data.answer);
      }

      if (this.data.id) {
        this.$hc
          .req()
          .put(url, data)
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功",
          })
          .subscribe(
            (x) => {
              this.$emit("success");
              this.$emit("update:open", false);
            },
            (x) => {}
          );
      } else {
        this.$hc
          .req()
          .post(url, data)
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功",
          })
          .subscribe(
            (x) => {
              this.$emit("success");
              this.$emit("update:open", false);
            },
            (x) => {}
          );
      }
    },
  },
};
</script>
<style scoped>
.option-label {
  padding-right: 15px;
}
</style>
