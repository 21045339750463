<template>
  <div>
    <div>
      <v-card style="margin-top: 25px">
        <v-card-title>
          <span>{{ countDown.text }}</span>
        </v-card-title>
        <v-card-subtitle class="pb-0">
          <span
            >请监考老师调整好监控摄像头设备, 使监控视频出现在下方,
            考试开始后将自动录制监控视频, 考试结束后监控视频将自动上传, 无需额外操作</span
          >
        </v-card-subtitle>

        <v-card-text>
          <v-row align="center">
            <v-col cols="6" offset="3">
              <v-select
                :items="cameras"
                v-model="cameraId"
                label="选择摄像头"
                item-text="name"
                item-value="id"
                @change="startCameraVideo"
              ></v-select>
            </v-col>
          </v-row>

          <v-row align="center">
            <video
              ref="_video_camera"
              @playing="onVideoCameraPlay"
              class="video"
              autoplay
            ></video>
            <canvas ref="_capture_camera" class="video" style="display: none"></canvas>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      started: false,
      cameraId: "",
      cameras: [],
      qinfo: {},
      qualification: {},
      sessionid: "",
      countDown: {
        text: "",
      },
      recorder: {
        camera: {},
        desktop: {},
      },
    };
  },
  watch: {
    id: {
      handler() {
        this.fetchCountdown(true);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {},
  beforeDestroy() {
    if (this.countDown && this.countDown.id) {
      clearInterval(this.countDown.id);
    }
    this.closeAll();
  },
  methods: {
    fetchCountdown(init) {
      this.loading = true;
      this.$hc
        .req()
        .get(`qualifications/${this.id}/countdown`)
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            if (init) {
              if (!x.Data.monitor) {
                this.$ns.cast("snack", {
                  text: "您不是监考老师",
                  color: "warning",
                });
                this.$router.replace("/qualifications");
              } else {
                this.init(x.Data);
              }
            }
          },
          (x) => {
            this.$ns.cast("snack", {
              text: x.Message,
              color: "warning",
            });
            this.$router.replace("/qualifications");
          }
        );
    },
    init(data) {
      var self = this;
      this.qualification = data;
      this.countDown.seconds = data.countdown;
      this.listCameras();
      this.sessionid = this.$uuid.v4();
      this.countDown.id = setInterval(() => {
        self.countDown.seconds = self.countDown.seconds - 1;
        var seconds = Math.abs(self.countDown.seconds);
        self.countDown.h = Math.floor((seconds % (60 * 60 * 24)) / (60 * 60));
        self.countDown.m = Math.floor((seconds % (60 * 60)) / 60);
        self.countDown.s = Math.floor(seconds % 60);
        if (self.countDown.seconds > 0) {
          self.countDown.text = `距考试开始 ${(self.countDown.h + "").padStart(
            2,
            "0"
          )}:${(self.countDown.m + "").padStart(2, "0")}:${(
            self.countDown.s + ""
          ).padStart(2, "0")}`;
        } else {
          if (!self.started) {
            self.enterQuiz();
          }
          var started = true;
          self.started = started;

          var toendSec = self.qualification.duration * 60 + self.countDown.seconds;
          if (toendSec <= 0) {
            this.closeAll();
            clearInterval(this.countDown.id);
            this.$ns.cast("snack", {
              text: "考试结束, 监控视频已上传",
              color: "success",
            });
            this.$router.replace("/qualifications");
          }
          var h = Math.floor((toendSec % (60 * 60 * 24)) / (60 * 60));
          var m = Math.floor((toendSec % (60 * 60)) / 60);
          var s = Math.floor(toendSec % 60);
          self.countDown.text = `距考试结束 ${(h + "").padStart(2, "0")}:${(
            m + ""
          ).padStart(2, "0")}:${(s + "").padStart(2, "0")}`;
        }
      }, 1000);
    },
    enterQuiz() {
      var id = setInterval(() => {
        if (window.__stream_camera && window.__stream_camera.active) {
          this.recordCamera();
          clearInterval(id);
        }
      }, 1000);
    },
    onVideoCameraPlay() {
      this.$refs._capture_camera.width = this.$refs._video_camera.clientWidth;
      this.$refs._capture_camera.height = this.$refs._video_camera.clientHeight;
    },

    listCameras() {
      var self = this;
      var cameraId = this.$ls.get("deviceId");
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: false })
        .then((x) => {
          window.__stream_camera = x;
          navigator.mediaDevices.enumerateDevices().then((devices) => {
            for (var i = 0; i < devices.length; i++) {
              var d = devices[i];
              if (d.deviceId && d.kind == "videoinput") {
                self.cameras.push({
                  name: d.label,
                  id: d.deviceId,
                });
              }
            }
            const tracks = x.getTracks();
            tracks.forEach((x) => x.stop());
            window.__stream_camera = null;
            if (cameraId) {
              self.cameraId = cameraId;
              self.startCameraVideo(cameraId);
            }
          });
        })
        .catch((x) => {});
    },

    closeAll() {
      if (this.recorder.camera.mr) {
        this.recorder.camera.mr.stop();
        this.recorder.camera.mr = null;
      }
      if (window.__stream_camera) {
        window.__stream_camera.getTracks().forEach((track) => {
          track.stop();
        });
        window.__stream_camera = null;
      }
    },
    startCameraVideo(deviceId) {
      if (window.__stream_camera) {
        window.__stream_camera.getTracks().forEach((track) => {
          track.stop();
        });
        window.__stream_camera = null;
      }
      var self = this;
      navigator.mediaDevices
        .getUserMedia({
          video: {
            deviceId: { exact: deviceId },
          },
          audio: false,
        })
        .then((x) => {
          window.__stream_camera = x;
          self.$refs._video_camera.srcObject = x;
        })
        .catch((error) => {});
    },
    recordCamera() {
      var self = this;
      this.recorder.camera.mr = new MediaRecorder(window.__stream_camera, {
        audioBitsPerSecond: 0,
        videoBitsPerSecond: 1280000,
        mimeType: "video/webm",
      });
      this.recorder.camera.mr.start(60000);
      this.recorder.camera.mr.ondataavailable = (e) => {
        var file = new File([e.data], "1.webm", {
          type: "video/webm",
        });
        var fe = new FormData();
        fe.append("type", "video_face");
        fe.append("status", "摄像头视频");
        fe.append("qualificationid", self.qualification.qid);
        fe.append("message", "摄像头视频");
        fe.append("sessionid", self.sessionid);
        fe.append("video", file);
        self.$hc
          .req()
          .post(`qualifications/logs`, fe)
          .go({
            lock: false,
            toastError: false,
            toastSuccess: "",
          })
          .subscribe(
            (x) => {},
            (x) => {}
          );
      };
    },
  },
};
</script>
<style scoped>
.video {
  max-width: 800px;
  max-height: 800px;
  margin: 0px auto;
}
</style>
