<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex xs12> 题目 </v-flex>
      <v-flex xs12>
        <vue-editor
          @text-change="handleQuizBlank"
          v-model="quizdata.title"
          :editor-toolbar="toolbar"
          placeholder="题目"
        ></vue-editor>
      </v-flex>
      <v-flex xs3 v-for="(a, index) in quizdata.answer" :key="index">
        <v-text-field
          :label="a.label"
          v-model="a.answer"
          :rules="rules.blank"
        ></v-text-field>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  name: "QuizInputEditor",
  props: ["quizdata"],
  data() {
    return {
      rules: {
        title: [(v) => !!v || "请填写考试题目"],
        blank: [(v) => !!v || "请填写答案"],
      },
      toolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["clean"], // remove formatting button
        ["image"],
      ],
    };
  },
  mounted() {
    if (this.quizdata && this.quizdata.answer && this.quizdata.answer.length) {
    } else {
      this.quizdata.answer = [];
      this.handleQuizBlank();
    }

    this.$forceUpdate();
  },
  beforeDestroy() {},
  methods: {
    handleQuizBlank() {
      var r = /(___)/g;
      var m;
      var ms = [];
      while ((m = r.exec(this.quizdata.title))) {
        ms.push(m[1]);
      }
      if (ms.length == 0) {
        this.quizdata.answer = [];
        this.$forceUpdate();
        return;
      }
      if (ms.length > 0 && ms.length != this.quizdata.answer.length) {
        this.quizdata.answer = [];
        for (var i = 0; i < ms.length; i++) {
          var lid = i + 1;
          this.quizdata.answer[i] = {
            id: i,
            label: "填空" + lid,
            answer: "",
          };
        }
        this.$forceUpdate();
      }
    },
  },
};
</script>
