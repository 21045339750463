<template>
  <div>
    <v-toolbar class="blue" dark>
      <v-toolbar-title class="text-h5 font-weight-bold">{{
        qualification.name
      }}</v-toolbar-title>
      <v-spacer> </v-spacer>
      <v-btn v-if="started" @click="backtolist">返回</v-btn>
    </v-toolbar>
    <v-card style="margin-top: 15px">
      <v-card v-for="(g, index) in qualification.Groups" :key="index" outlined>
        <v-toolbar extended color="blue  lighten-4" dense>
          <v-toolbar-title>{{ g.title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <template slot="extension">
            <span v-html="g.description"></span>
          </template>
        </v-toolbar>
        <v-card-text>
          <qualification-quiz
            v-for="(quiz, i) in g.Quizs"
            :key="i"
            :quiz="quiz"
          ></qualification-quiz>
        </v-card-text>
      </v-card>
      <v-card-actions v-if="started">
        <v-spacer> </v-spacer>
        <v-btn @click="backtolist">返回</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      loading: true,
      started: false,
      qinfo: {},
      qualification: {
        Groups: [],
      },
    };
  },
  watch: {
    id: {
      handler() {
        this.fetchQuiz();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {},

  computed: {
    pages() {
      if (this.data.Total) {
        return Math.ceil(this.data.Total / this.pagination.rowsPerPage);
      }
      return 0;
    },
  },
  methods: {
    testsave() {
      var qa = {
        taken: 1,
        q: JSON.stringify(this.qualification),
      };
      this.$ls.set(`qualification-${this.id}`, JSON.stringify(qa));
    },
    testload() {
      var s = this.$ls.get(`qualification-${this.id}`);
      if (s) {
        var qa = JSON.parse(s);
        this.qualification = JSON.parse(qa.q);
      }
    },
    fetchQuiz() {
      this.loading = true;
      this.$hc
        .req()
        .get(`admin/qualificationquizs/${this.id}/preview`)
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.started = true;
            this.qualification = x.Data;
          },
          (x) => {}
        );
    },
    backtolist() {
      this.$router.replace(`/admin/qualifications/${this.id}/quiz`);
    },
  },
};
</script>
