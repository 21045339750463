<template>
  <div>
    <v-card>
      <v-card-title>修改密码</v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation autocomplete="random-string">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="vm.OldPass"
                  autocomplete="new-password"
                  :rules="rules.oldPassRules"
                  prepend-icon="lock"
                  :counter="50"
                  label="旧密码"
                  type="password"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="vm.NewPass"
                  autocomplete="new-password"
                  :rules="rules.pass1Rules"
                  prepend-icon="lock"
                  :counter="50"
                  label="新密码"
                  type="password"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="vm.password2"
                  autocomplete="new-password"
                  :rules="[confirmPass]"
                  prepend-icon="lock"
                  :counter="50"
                  label="确认新密码"
                  type="password"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" :disabled="!valid" @click="save">保存</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tk: {},
      valid: false,
      loading: true,
      vm: {
        valid: false,
      },
      rules: {
        oldPassRules: [
          v => !!v || "请填旧密码",
          v =>
            (v && v.length <= 20 && v.length >= 3) ||
            "密码长度必须在6到50个字符之间"
        ],
        pass1Rules: [
          v => !!v || "请填写密码",
          v =>
            (v && v.length <= 50 && v.length >= 6) ||
            "密码长度必须在6到50个字符之间"
        ]
      }
    };
  },  
  methods: {
    confirmPass(value) {
      if (!value) return "请填写确认新密码";
      return value === this.vm.NewPass || "密码不一致";
    },
    save() {
      if (!this.$refs.form.validate()) return;
      this.$hc
        .req()
        .put(`members/pass`,this.vm)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "操作成功"
        })
        .subscribe(
          x => {
            this.fetchMessages();
          },
          x => {}
        );
    }
  }
};
</script>
