<template>
  <v-app id="inspire">
    <v-app-bar
      app
      clipped-left
      color="wihte"
      height="70"
      style="background-color: #ffffff"
    >
      <v-toolbar-title>
        <span class="text-h5 font-weight-black green--text">在线培训考核系统</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title style="padding-top: 20px">
        <v-tabs
          grow
          align-with-title
          v-model="header_tab"
          optional
          color="green"
          slider-color="green"
          slider-size="5"
          class="title-nav"
        >
          <v-tab class="text-h6 green--text font-weight-black" to="/activelectures"
            >课程</v-tab
          >
          <v-tab class="text-h6 green--text font-weight-black" to="/examinations"
            >练习</v-tab
          >
          <v-tab class="text-h6 green--text font-weight-black" to="/qualifications"
            >考试</v-tab
          >
          <v-tab class="text-h6 green--text font-weight-black" to="/topics/0">法规</v-tab>
          <v-tab class="text-h6 green--text font-weight-black" to="/topics/1">标准</v-tab>
        </v-tabs>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-btn
        v-if="!(profile && profile.xingming)"
        color="primary"
        to="/register"
        rounded
        outlined
        large
        dark
        >注册</v-btn
      >
      <v-btn
        v-if="!(profile && profile.xingming)"
        class="ma-2"
        to="/"
        color="primary"
        rounded
        large
        >登录</v-btn
      >
      <v-toolbar-title v-if="profile && profile.xingming">
        <span class="primary--text">你好, {{ profile.xingming }}</span>
      </v-toolbar-title>
      <v-tooltip bottom v-if="profile && profile.xingming">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" icon v-on="on" @click.stop="logout">
            <v-icon>logout</v-icon>
          </v-btn>
        </template>
        <span>登出系统</span>
      </v-tooltip>
    </v-app-bar>
    <router-view></router-view>
    <v-snackbar
      v-model="msg.open"
      :bottom="!msg.top"
      :color="msg.color || 'success'"
      :left="msg.left"
      :multi-line="msg.ml"
      :right="msg.right"
      :timeout="msg.timeout || 5000"
      :top="msg.top"
      :vertical="false"
    >
      {{ msg.text }}
      <v-btn dark text @click="msg.open = false">关闭</v-btn>
    </v-snackbar>
    <div v-if="loading" class="loading">Loading&#8230;</div>
  </v-app>
</template>

<script>
import { mapState, mapWritableState } from "pinia";
import { useUserStore } from "@/stores/userStore.js";
export default {
  name: "Landing",
  data: () => ({
    menu: "",
    loading: false,
    msg: {
      open: false,
      timeout: 5000,
      color: "success",
      left: false,
      right: false,
      ml: false,
      top: true,
      text: "",
    },
  }),
  computed: {
    ...mapState(useUserStore, ["profile"]),
    ...mapWritableState(useUserStore, ["header_tab"]),
  },
  mounted() {
    this.$face.init();
    this.$ns.on("snack").subscribe((x) => {
      var opt = {
        open: false,
        timeout: 5000,
        color: "success",
        left: false,
        right: false,
        ml: false,
        top: true,
        text: "",
      };
      Object.assign(opt, x.data);
      this.msg = opt;
      this.msg.open = true;
    });
    this.$ns.on("lock").subscribe((x) => (this.loading = x.data));
    const userStore = useUserStore();
    if (!userStore.profile && userStore.userToken) {
      userStore.getUserProfile().subscribe((x) => {});
    }
  },
  methods: {
    logout() {
      const userStore = useUserStore();
      userStore.logout();
      this.$router.push("/");
    },
    gologin() {
      this.$router.push("/");
    },
    goto(url) {
      window.location.href = url;
    },
    switchMenu(m) {
      this.menu = m;
    },
    switchTab(url) {
      this.$router.push(url);
    },
  },
};
</script>
<style scoped>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.5) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.5) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

>>> .title-nav .v-tabs-slider {
  margin: 0px auto;
  width: 45px;
}
</style>
