<template>
  <v-dialog v-model="open" persistent max-width="800px">
    <v-form ref="form" v-model="valid" lazy-validation autocomplete="random-string">
      <v-card>
        <v-card-title>
          <span class="headline">{{ title }}</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  name="hack-chrome"
                  label="考试名称"
                  v-model="data.name"
                  :rules="rules.name"
                  autocomplete="false"
                ></v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-menu
                  ref="startDialog"
                  v-model="dialogSet.startDialog"
                  persistent
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="data.start_date"
                      label="考试开始日期"
                      prepend-icon="event"
                      :rules="rules.start_date"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="data.start_date"
                    no-title
                    @input="dialogSet.startDialog = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs6>
                <v-menu
                  ref="startTimeDialog"
                  v-model="dialogSet.startTimeDialog"
                  :return-value.sync="data.start_time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="data.start_time"
                      label="考试开始时间"
                      prepend-icon="access_time"
                      :rules="rules.start_time"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-model="data.start_time"
                    v-if="dialogSet.startTimeDialog"
                    @click:minute="$refs.startTimeDialog.save(data.start_time)"
                  ></v-time-picker>
                </v-menu>
              </v-flex>
              <v-flex xs4>
                <v-menu
                  ref="endDateDialog"
                  v-model="dialogSet.endDateDialog"
                  persistent
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="data.end_date"
                      label="考试结束日期"
                      prepend-icon="event"
                      :rules="rules.end_date"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="data.end_date"
                    no-title
                    @input="dialogSet.endDateDialog = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs4>
                <v-menu
                  ref="endTimeDialog"
                  v-model="dialogSet.endTimeDialog"
                  :return-value.sync="data.end_time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="data.end_time"
                      label="考试结束时间"
                      prepend-icon="access_time"
                      :rules="rules.end_time"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-model="data.end_time"
                    v-if="dialogSet.endTimeDialog"
                    @click:minute="$refs.endTimeDialog.save(data.end_time)"
                  ></v-time-picker>
                </v-menu>
              </v-flex>
              <v-flex xs4>
                <v-text-field
                  label="考试时长(分钟)"
                  prepend-icon="access_time"
                  v-model="data.duration"
                  :rules="rules.duration"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-select
                  :items="video_rates"
                  v-model="data.video_bit_rate"
                  label="选择录像清晰度(实际数据量根据考试设备会有所浮动)"
                  item-text="id"
                  item-value="value"
                ></v-select>
              </v-flex>
              <v-flex xs3>
                <v-switch v-model="data.recorddesktop" inset label="录制桌面"></v-switch>
              </v-flex>
              <v-flex xs3>
                <v-switch v-model="data.camera" inset label="摄像头"></v-switch>
              </v-flex>
              <v-flex xs3>
                <v-switch
                  v-model="data.camera_shoot"
                  @change="switch_face_change"
                  inset
                  label="人脸识别"
                ></v-switch>
              </v-flex>
              <v-flex xs3>
                <v-text-field
                  label="识别间隔(秒)"
                  v-model="data.camera_shoot_interval"
                  :disabled="!data.camera_shoot"
                  :rules="rules.camera_shoot_interval"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="$emit('update:open', false)">取消</v-btn>
          <v-btn color="warning" :disabled="!valid" @click="addOrUpdateQualification"
            >保存</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: "QualificationEditDialog",
  props: ["open"],
  data() {
    return {
      data: {},
      valid: false,
      video_rates: [
        { id: "32K(约3M/考生/分钟)", value: 320000 },
        { id: "64K(约6M/考生/分钟)", value: 640000 },
        { id: "128K(约12M/考生/分钟)", value: 12800000 },
        { id: "512K(约24M/考生/分钟)", value: 51200000 },
      ],
      dialogSet: {},
      rules: {
        name: [(v) => !!v || "请填写考试名称"],
        duration: [(v) => !!v || "请填写考试时长", (v) => /\d+/.test(v) || "请填写数字"],
        start_date: [(v) => !!v || "请选择考试开始日期"],
        start_time: [(v) => !!v || "请选择考试开始时间"],
        end_date: [
          (v) => !!v || "请选择考试结束日期",
          (v) => {
            var n = this.$moment(this.data.start_date);
            var c = this.$moment(v);
            return c.diff(n, "days") >= 0 || "考试结束日期不能小于开始日期";
          },
        ],
        end_time: [
          (v) => !!v || "请选择考试结束时间",
          (v) => {
            if (!this.data.end_date) return true;
            var n = this.$moment(
              this.data.start_date +
                (this.data.start_time ? " " + this.data.start_time : ""),
              ["YYYY-MM-DD HH:mm", "YYYY-MM-DD"]
            );
            var c = this.$moment(this.data.end_date + " " + v, [
              "YYYY-MM-DD HH:mm",
              "YYYY-MM-DD",
            ]);
            return c.diff(n, "minutes") >= 0 || "考试开始时间不能小于结束时间";
          },
        ],

        camera_shoot_interval: [],
        ahead: [
          (v) => !!v || "请填写可提前进入准备考试(分钟)",
          (v) => /\d+/.test(v) || "请填写数字",
        ],
      },
    };
  },
  computed: {
    title() {
      if (this.data && this.data.id) {
        return "编辑在线考试";
      }
      return "新建在线考试";
    },
  },
  methods: {
    loadData(data) {
      this.data = data;
      if (this.data.camera && this.data.camera_shoot) {
        this.rules.camera_shoot_interval = [
          (v) => !!v || "请填写识别间隔",
          (v) => /\d+/.test(v) || "请填写数字",
          (v) => {
            if (!/\d+/.test(v)) return true;
            return v * 1 >= 30 || "识别间隔要大于等于30秒";
          },
        ];
      } else {
        this.rules.camera_shoot_interval = [];
      }
      if (!this.data.video_bit_rate) {
        this.data.video_bit_rate = 320000;
      }
    },

    switch_face_change() {
      if (!this.data.camera_shoot) {
        this.rules.camera_shoot_interval = [];
        this.data.camera_shoot_interval = 0;
      } else {
        this.rules.camera_shoot_interval = [
          (v) => !!v || "请填写识别间隔",
          (v) => /\d+/.test(v) || "请填写数字",
          (v) => {
            if (!/\d+/.test(v)) return true;
            return v * 1 >= 30 || "识别间隔要大于等于30秒";
          },
        ];
      }
    },
    addOrUpdateQualification() {
      if (!this.$refs.form.validate()) return;
      this.data.start = this.data.start_date + " " + this.data.start_time;
      this.data.end = this.data.end_date + " " + this.data.end_time;
      if (this.data.id) {
        this.$hc
          .req()
          .put(`admin/qualifications`, this.data)
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功",
          })
          .subscribe(
            (x) => {
              this.$emit("success");
              this.$emit("update:open", false);
            },
            (x) => {}
          );
      } else {
        this.$hc
          .req()
          .post(`admin/qualifications`, this.data)
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功",
          })
          .subscribe(
            (x) => {
              this.$emit("success");
              this.$emit("update:open", false);
            },
            (x) => {}
          );
      }
    },
  },
};
</script>
