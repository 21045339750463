<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex xs12> 题目 </v-flex>
      <v-flex xs12>
        <vue-editor
          v-model="quizdata.title"
          :editor-toolbar="toolbar"
          placeholder="题目"
        ></vue-editor>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12 v-if="quizdata.answer && quizdata.answer.length">
        <v-radio-group v-model="quizdata.answer[0].answer" mandatory>
          <v-radio
            v-for="(o, index) in quizdata.options"
            :label="o.title"
            :key="index"
            :value="o.label"
          >
          </v-radio>
        </v-radio-group>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  name: "QuizJudgementEditor",
  props: ["quizdata"],
  data() {
    return {
      answer: "",
      rules: {
        title: [(v) => !!v || "请填写考试题目"],
        blank: [(v) => !!v || "请填写答案"],
      },
      toolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["clean"], // remove formatting button
        ["image"],
      ],
    };
  },
  mounted() {
    if (
      !this.quizdata.options ||
      !this.quizdata.options.length ||
      !this.quizdata.answer ||
      !this.quizdata.answer.length
    ) {
      this.quizdata.options = [
        {
          label: "A",
          title: "正确",
          checked: false,
        },
        {
          label: "B",
          title: "错误",
          checked: false,
        },
      ];
      this.quizdata.answer = [];
      this.quizdata.answer[0] = {
        id: 0,
        label: "判断题",
        answer: "A",
      };
    } else {
    }
    this.$forceUpdate();
  },
  methods: {},
};
</script>
