<template>
  <v-dialog v-model="open" persistent max-width="1000px">
    <v-form ref="form" v-model="valid" lazy-validation autocomplete="random-string">
      <v-card>
        <v-card-title>
          <span class="headline">{{ title }}</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs4>
                <v-text-field
                  label="序号"
                  v-model="data.ordindex"
                  :rules="rules.ordindex"
                  autocomplete="false"
                ></v-text-field>
              </v-flex>
              <v-flex xs4>
                <v-select
                  :items="quizType"
                  v-model="data.type"
                  label="题型"
                  :rules="rules.type"
                ></v-select>
              </v-flex>

              <v-flex xs4>
                <v-text-field
                  label="分数"
                  v-model="data.point"
                  :rules="rules.point"
                  autocomplete="false"
                ></v-text-field>
              </v-flex>
              <v-flex xs12> 题目 </v-flex>
              <v-flex xs12>
                <vue-editor
                  text-change="titleChanged"
                  v-model="data.title"
                  :editor-toolbar="toolbar"
                  placeholder="题目"
                ></vue-editor>
              </v-flex>
            </v-layout>
            <v-layout
              wrap
              v-if="data.type == '填空题' && data.answer && data.answer.length"
            >
              <v-flex xs4 v-for="(a, index) in data.answer" :key="index">
                <v-text-field
                  :label="a.label"
                  v-model="a.answer"
                  :rules="rules.blank"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap v-if="data.type == '单选题'">
              <v-flex xs12>
                <v-radio-group v-model="data.answer[0].answer" mandatory>
                  <v-radio
                    v-for="(o, index) in data.options"
                    :label="o.label"
                    :key="index"
                    :value="o.label"
                  >
                    <template v-slot:label>
                      <span class="option-label"> {{ o.label }}</span>
                      <vue-editor
                        v-model="o.title"
                        :editor-toolbar="toolbar"
                      ></vue-editor>
                      <v-btn v-if="data.options.length > 1" @click="deleteOption(o)"
                        >删除</v-btn
                      >
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-flex>
              <v-flex xs1></v-flex>
              <v-flex xs11>
                <v-btn @click="addOption">增加选项</v-btn>
              </v-flex>
            </v-layout>
            <v-layout wrap v-if="data.type == '多选题'">
              <v-flex xs12>
                <v-checkbox
                  v-for="(o, index) in data.options"
                  :label="o.label"
                  :key="index"
                  v-model="o.checked"
                >
                  <template v-slot:label>
                    <span class="option-label"> {{ o.label }}</span>
                    <vue-editor v-model="o.title" :editor-toolbar="toolbar"></vue-editor>
                    <v-btn v-if="data.options.length > 1" @click="deleteOption(o)"
                      >删除</v-btn
                    >
                  </template>
                </v-checkbox>
              </v-flex>
              <v-flex xs1></v-flex>
              <v-flex xs11>
                <v-btn @click="addOption">增加选项</v-btn>
              </v-flex>
            </v-layout>
            <v-layout wrap v-if="data.type == '判断题'">
              <v-flex xs12>
                <v-radio-group v-model="data.answer[0].answer" mandatory>
                  <v-radio
                    v-for="(o, index) in data.options"
                    :label="o.title"
                    :key="index"
                    :value="o.label"
                  >
                  </v-radio>
                </v-radio-group>
              </v-flex>
            </v-layout>
            <v-layout wrap v-if="data.type == '问答题'">
              <v-flex xs12>
                <v-textarea
                  label="回答"
                  :rows="3"
                  v-model="data.answer[0].answer"
                  :rules="rules.blank"
                  autocomplete="false"
                ></v-textarea>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="$emit('update:open', false)">取消</v-btn>
          <v-btn color="warning" :disabled="!valid" @click="addOrUpdate">保存</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: "QualificationQuizEditDialog",
  props: ["open"],
  data() {
    return {
      data: {},
      initializing: false,
      quizType: ["填空题", "单选题", "多选题", "判断题", "问答题"],
      toolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["clean"], // remove formatting button
        ["image"],
      ],
      valid: false,
      dialogSet: {},
      rules: {
        title: [(v) => !!v || "请填写考试题目"],
        blank: [(v) => !!v || "请填写答案"],
        option: [(v) => !!v || "请填写选项"],
        ordindex: [(v) => !!v || "请填写序号", (v) => /\d+/.test(v) || "请填写数字"],
        type: [(v) => !!v || "请选择题型"],
        point: [(v) => !!v || "请填写分数", (v) => /\d+/.test(v) || "请填写数字"],
      },
    };
  },
  watch: {
    "data.type": {
      handler() {
        if (this.initializing) return;
        this.processQuiz();
      },
    },
    "data.title": {
      handler() {
        this.handleQuizBlank();
      },
    },
  },
  computed: {
    title() {
      if (this.data && this.data.id) {
        return "编辑考试题目";
      }
      return "新建考试题目";
    },
  },
  methods: {
    loadData(data) {
      this.initializing = true;
      var d = { ...data };
      if (d.options) {
        d.options = JSON.parse(d.options);
      }
      if (d.answer) {
        d.answer = JSON.parse(d.answer);
      }
      this.data = d;
      this.$nextTick(() => {
        this.$forceUpdate();
        this.initializing = false;
      });
    },

    processQuiz() {
      this.data.options = [];
      this.data.answer = [];
      if (this.data.type === "填空题") {
        this.handleQuizBlank();
      } else if (this.data.type === "单选题") {
        this.data.options = [
          {
            label: "A",
            title: "",
            checked: false,
          },
          {
            label: "B",
            title: "",
            checked: false,
          },
          {
            label: "C",
            title: "",
            checked: false,
          },
          {
            label: "D",
            title: "",
            checked: false,
          },
        ];
        this.data.answer[0] = {
          id: 0,
          label: "单选题",
          answer: "",
        };
      } else if (this.data.type === "多选题") {
        this.data.options = [
          {
            label: "A",
            title: "",
            checked: false,
          },
          {
            label: "B",
            title: "",
            checked: false,
          },
          {
            label: "C",
            title: "",
            checked: false,
          },
          {
            label: "D",
            title: "",
            checked: false,
          },
        ];
        this.data.answer[0] = {
          id: 0,
          label: "多选题",
          answer: "",
        };
      } else if (this.data.type === "判断题") {
        this.data.options = [
          {
            label: "A",
            title: "正确",
            checked: false,
          },
          {
            label: "B",
            title: "错误",
            checked: false,
          },
        ];
        this.data.answer[0] = {
          id: 0,
          label: "判断题",
          answer: "A",
        };
      } else if (this.data.type === "问答题") {
        this.data.answer[0] = {
          id: 0,
          label: "问答题",
          answer: "",
        };
      }
    },
    deleteOption(option) {
      var idx = 0;
      for (var i = 0; i < this.data.options.length; i++) {
        if (this.data.options[i].label == option.label) {
          idx = i;
          break;
        }
      }
      this.data.options.splice(idx, 1);
      for (i = 0; i < this.data.options.length; i++) {
        this.data.options[i].label = String.fromCharCode(65 + i);
      }
      this.$forceUpdate();
    },
    addOption() {
      var idx = this.data.options.length;
      this.data.options[idx] = {
        label: String.fromCharCode(idx + 65),
        title: "",
        checked: false,
      };
      this.$forceUpdate();
    },
    handleQuizBlank() {
      if (this.data.type === "填空题") {
        var r = /(___)/g;
        var m;
        var ms = [];
        while ((m = r.exec(this.data.title))) {
          ms.push(m[1]);
        }
        if (ms.length == 0) {
          this.data.answer = [];
          return;
        }
        if (ms.length > 0 && ms.length != this.data.answer.length) {
          for (var i = 0; i < ms.length; i++) {
            var lid = i + 1;
            this.data.answer[i] = {
              id: i,
              label: "填空" + lid,
              answer: "",
            };
          }
        }
      }
    },

    addOrUpdate() {
      if (!this.$refs.form.validate()) return;
      var data = { ...this.data };
      data.options = JSON.stringify(this.data.options);
      if (this.data.type === "多选题") {
        var ans = [];
        for (var i = 0; i < this.data.options.length; i++) {
          var o = this.data.options[i];
          if (o.checked) {
            ans.push({
              id: i + 1,
              label: "多选题",
              answer: o.label,
            });
          }
        }
        data.answer = JSON.stringify(ans);
      } else {
        data.answer = JSON.stringify(this.data.answer);
      }

      if (this.data.id) {
        this.$hc
          .req()
          .put(`admin/qualificationquizs`, data)
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功",
          })
          .subscribe(
            (x) => {
              this.$emit("success");
              this.$emit("update:open", false);
            },
            (x) => {}
          );
      } else {
        this.$hc
          .req()
          .post(`admin/qualificationquizs`, data)
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功",
          })
          .subscribe(
            (x) => {
              this.$emit("success");
              this.$emit("update:open", false);
            },
            (x) => {}
          );
      }
    },
  },
};
</script>
<style scoped>
.option-label {
  padding-right: 15px;
}
</style>
