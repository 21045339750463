<template>
  <v-container fluid style="padding-top: 150px">
    <v-layout align-center justify-center>
      <v-flex xs12 sm6 md3 style="padding-top: 100px; padding-bottom: 25px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>登录</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" to="/register" icon large slot="activator">
                  <v-icon small>注册</v-icon>
                </v-btn>
              </template>
              <span>注册新账户</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="vm.account"
                :rules="rules.nameRules"
                prepend-icon="person"
                autocomplete="new-password"
                label="用户名(身份证号)"
                type="text"
                required
              ></v-text-field>
              <v-text-field
                v-model="vm.password"
                autocomplete="new-password"
                :rules="rules.passRules"
                prepend-icon="lock"
                label="密码"
                type="password"
                @keyup.enter.stop="login"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="!valid" @click="login">登录</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { useUserStore } from "@/stores/userStore.js";
export default {
  data: () => ({
    valid: false,
    vm: {
      account: "",
      password: "",
    },
    rules: {
      nameRules: [(v) => !!v || "请填写用户名"],
      passRules: [(v) => !!v || "请填写密码"],
    },
  }),

  methods: {
    login() {
      if (!this.$refs.form.validate()) return;
      const userStore = useUserStore();
      userStore.login(this, { sfz: this.vm.account, password: this.vm.password });
      // this.$hc
      //   .req()
      //   .post(
      //     "members/login",
      //     { sfz: this.vm.account, password: this.vm.password },
      //     (x) => {
      //       this.$ls.set("token", JSON.stringify(x.Data));
      //     }
      //   )
      //   //.get('api/configurations/default', x => this.ns.cast('ms:cfg_default', x.Data))
      //   .go({
      //     lock: true,
      //     toastError: false,
      //     toastSuccess: "登陆成功",
      //   })
      //   .subscribe(
      //     (x) => this.$router.push("/"),
      //     (x) => {
      //       this.$ns.cast("snack", {
      //         text: "用户名或密码错误",
      //         color: "error",
      //       });
      //     }
      //   );
    },
  },
};
</script>
