<template>
  <div>
    <v-card>
      <v-toolbar>
        <v-text-field
          label="姓名"
          clearable
          v-model="q.q"
          hide-details
          :append-icon="'search'"
          @keyup.enter.stop="search()"
          @click:append="search()"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-select hide-details :items="searchAdminItems" v-model="q.isadmin" label="管理员"></v-select>
        <v-spacer></v-spacer>
        <v-btn color="secondary" @click="search()">检索</v-btn>
      </v-toolbar>
    </v-card>
    <v-card style="margin-top:20px">
      <v-data-table
        :headers="headers"
        :items="data.Payload"
        :server-items-length="data.Total"
        :loading="loading"
        hide-default-footer
        item-key="id"
        :fixed-header="true"
      >
        <template v-slot:item.zhuceshijian="props">{{props.item.zhuceshijian | moment('LL')}}</template>
        <template v-slot:item.isadmin="props">{{props.item.isadmin? '是':'否'}}</template>
        <template v-slot:item.op="props">
          <v-btn
            v-if="props.item.id!=1"
            :color="props.item.isadmin?'info':'success'"
            small
            @click.native.stop="toggleAdmin(props.item)"
          >{{props.item.isadmin?'取消管理员':'设为管理员'}}</v-btn>
        </template>
        <template v-slot:no-data>没有用户被找到</template>
      </v-data-table>
      <div class="text-xs-center pt-2">
        <v-pagination v-model="pagination.page" :length="pages" :total-visible="10"></v-pagination>
      </div>
    </v-card>
  </div>
</template>


<script>
export default {
  data() {
    return {
      searchAdminItems: ["无", "是", "否"],
      q: {},
      loading: true,
      pagination: {
        page: 1,
        rowsPerPage: 15
      },
      data: {},
      headers: [
        {
          text: "ID",
          value: "id",
          align: "center",
          sortable: false,
          class: "data-table-header"
        },
        {
          text: "姓名",
          value: "xingming",
          align: "center",
          sortable: false,
          class: "data-table-header"
        },
        {
          text: "联系电话",
          value: "dianhua",
          align: "center",
          sortable: false,
          class: "data-table-header"
        },
        {
          text: "电子邮件",
          value: "email",
          align: "center",
          sortable: false,
          class: "data-table-header"
        },
        {
          text: "注册时间",
          value: "zhuceshijian",
          align: "center",
          sortable: false,
          class: "data-table-header"
        },
        {
          text: "管理员",
          value: "isadmin",
          align: "center",
          sortable: false,
          class: "data-table-header"
        },
        {
          text: "操作",
          value: "op",
          align: "center",
          sortable: false,
          class: "data-table-header"
        }
      ]
    };
  },
  watch: {
    pagination: {
      handler() {
        this.fetchUsers();
      },
      deep: true
    }
  },
  mounted() {
    this.fetchUsers();
  },
  computed: {
    pages() {
      if (this.data.Total) {
        return Math.ceil(this.data.Total / this.pagination.rowsPerPage);
      }
      return 0;
    }
  },
  methods: {
    search() {
      if (this.pagination.page != 1) {
        this.pagination.page = 1;
      } else {
        this.findUsers();
      }
    },
    findUsers() {
      if (this.pagination.page > 1) {
        this.pagination.page = 1;
      } else {
        this.fetchUsers();
      }
    },
    fetchUsers() {
      this.loading = true;
      this.$hc
        .req()
        .get(
          `admin/members/search?pagesize=${this.pagination.rowsPerPage}&pageindex=${
            this.pagination.page
          }&q=${this.q.q || ""}&isadmin=${
            this.q.isadmin == "是"
              ? "true"
              : this.q.isadmin == "否"
              ? "false"
              : ""
          }`
        )
        .go({
          lock: true,
          toastError: false,
          toastSuccess: ""
        })
        .subscribe(
          x => {
            this.loading = false;
            this.data = x.Data;
          },
          x => {}
        );
    },
    toggleAdmin(u) {
      let msg = `确定要把[${u.xingming}]设为管理员吗?`;
      if (u.isadmin) {
        msg = `确定要取消[${u.xingming}]的管理员权限吗?`;
      }
      this.$confirm(msg).then(x => {
        if (x) {
          this.$hc
            .req()
            .put(`admin/members/setadmin/${u.id}`,{})
            .go({
              lock: true,
              toastError: true,
              toastSuccess: "操作成功"
            })
            .subscribe(
              x => {
                this.fetchUsers();
              },
              x => {}
            );
        }
      });
    }
  }
};
</script>
